import React from 'react'
import HeroBSSR from '../components/Home/HeroBSSR'
import HeroTBYB from '../components/Home/HeroTBYB'
import NeedAdvice from '../components/Home/NeedAdvice'
import Socials from '../components/Home/Socials'
import LatestKit from '../components/Home/LatestKit'
import Quotes from '../components/Home/Quotes'

const Home = () =>
  <div id="home">
    <HeroBSSR/>
    <NeedAdvice/>
    <LatestKit/>
    <HeroTBYB/>
    <Quotes/>
    <Socials/>
  </div>

export default Home