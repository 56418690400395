import {
  ConsentCookie, CookieOptions,
  ConsentEssential, ConsentAnalytics, ConsentSearch
} from '../config/Site';
import { isDefined } from './Misc';

export function cookies() {
    return (typeof document !== 'undefined')
      ? document.cookie.split('; ')
      : [ ];
}

export function getCookie(name) {
    let cookie = cookies().find( row => row.startsWith(name) );
    return cookie
        ? cookie.split('=')[1]
        : undefined;
}

export function setCookie(name, value, opts) {
    if (typeof document == 'undefined')
        return;

    let cookie = [name, value].join('=');
    if (opts) {
        cookie += '; ' + Object.keys(opts).map(
            k => [k, opts[k]].join('=')
        ).join('; ');
        if (opts.SameSite === 'None') {
          cookie += '; Secure';
        }
    }
    document.cookie = cookie;
}

export function setConsentCookie(value) {
    setCookie(ConsentCookie, value, CookieOptions);
}

export function setConsent(options=[]) {
    setConsentCookie(options.filter( o => isDefined(o) ).join('|'));
}

export function getConsentCookie() {
    return getCookie(ConsentCookie);
}

export function getConsent() {
  const cookie = getConsentCookie();
  return isDefined(cookie) && cookie.length
    ? cookie.split('|')
    : [ ];
}

export function getConsentEssential() {
    return getConsent().includes(ConsentEssential);
}

export function getConsentAnalytics() {
    return getConsent().includes(ConsentAnalytics);
}

export function getConsentSearch() {
    return getConsent().includes(ConsentSearch);
}
