import React from 'react'
import Sales from '../../context/Sales'
import Card from '../Product/Card';
import ShowPrices from './ShowPrices';
import Sort from './Sort';
import { inflect } from '../../utils/Markup'

export const Browse = ({ sales, product, setRef }) => {
  if (! sales.browsing) {
    return;
  }
  const all = (sales.bodyType || sales.lengthMount || sales.category)
    ? ' '
    : ' all products '
  return <div className="browse-results mar-t-3">
    <div className="flex space center browse-summary">
      <div>
        <h4 className="mar-t-none mar-b-none" ref={product ? null : setRef}>
        Browsing{all}{' '}
          {sales.bodyType    ? sales.bodyType.title  : '' }{' '}
          {sales.lensMount   ? sales.lensMount.name : '' }{' '}
          {sales.category    ? sales.category.title.toLowerCase()  : '' }
          {sales.searchWords ? ` matching ${sales.searchWords.map( w => `"${w}"` ).join(' and ')}` : ''}
        </h4>
      { sales.browseResults.length
        ? <h5 className="green">{inflect(sales.browseResults.length, 'product')} matching your selection</h5>
        : <h5 className="green">There aren't any products matching your selection</h5>
      }
      </div>
      { sales.browseResults.length
        ? <div className="prices-sort">
            <ShowPrices/>
            <Sort/>
          </div>
        : null
      }
    </div>
    { sales.browseResults.length
      ? <div className="product-grid">
        { sales.browseResults.map(
            product => <Card key={product.id} product={product}/>
        )}
      </div>
      : null
    }
  </div>
}


export default Sales.Consumer(Browse)
