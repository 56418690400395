import React from 'react'
import Sales from '../../context/Sales'

const Sort = ({sales}) => {
  return <div className="sort">
    <div>Sort by</div>
    <select name="sort" value={sales.sortOrder} onChange={e => sales.setSortOrder(e.target.value)}>
      <option value="name">Name</option>
      <option value="price">Price</option>
      <option value="focal_length">Focal Length</option>
      <option value="aperture">Aperture</option>
    </select>
    <select name="reverse" value={sales.sortDirection} onChange={e => sales.setSortDirection(e.target.value)}>
      <option value="ascending">Ascending</option>
      <option value="descending">Descending</option>
    </select>
  </div>
}

export default Sales.Consumer(Sort)