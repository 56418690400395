import React from 'react'
import Sales from '../../context/Sales'
import Input from './Input'

const Search = () => {
  return <div className="search flex center space">
    <div className="flex-grow">
      <Input/>
    </div>
  </div>
}

export default Sales.Consumer(Search)
