import { IdealPCKey } from "../config/Site";
import { Client, lookupAddress } from "@ideal-postcodes/core-axios"
import { capitalizeWords } from "@abw/badger-ui";
import { isDefined } from "./Misc";

const client = new Client({ api_key: IdealPCKey });

export const AddressFinder = ({query}) => {
  return lookupAddress({ client, query, context: 'gbr' }).then(
    addresses => addresses.map(sanitise)
  )
  //.catch(
  //  error => console.log("ERROR: ", error)
  //)
}

const sanitise = address => {
  let data = {
    line1:    address.line_1,
    line2:    address.line_2,
    line3:    address.line_3,
    town:     capitalizeWords(address.post_town.toLowerCase()),
    county:   address.postal_county,
    postcode: address.postcode,
    country:  address.country,
  };
  // if there's an organisation name in line1 then move it to the company field
  if (address.organisation_name && address.organisation_name === address.line_1) {
    data.company = address.organisation_name;
    data.line1 = data.line2;
    data.line2 = data.line3;
    data.line3 = undefined;
  }
  data.summary = ['company', 'line1', 'line2', 'line3', 'town', 'postcode']
    .map( key => data[key] )
    .filter( value => isDefined(value) && value.length )
    .join(', ');
  // console.log('sanitised address: ', address);
  return data;
}

export default AddressFinder