import React from 'react'
import Sales from '../../context/Sales'
import { range } from '../../utils'

const ShowPrices = ({sales}) => {
  return <div className="prices">
    <div>Duration</div>
    <select
      value={sales.hireDays()}
      onChange={ e => sales.setHireDays(e.target.value) }
    >
      { range(3, 29).map(
        days => <option key={days} value={days}>{days} days</option>
      )}
    </select>
  </div>
}

export default Sales.Consumer(ShowPrices)