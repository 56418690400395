import React from 'react'
import BodyTypes from '../../config/BodyTypes'
import Sales from '../../context/Sales'

export const Manufacturers = ({sales}) =>
  <div className="manufacturers">
  { BodyTypes.map(
      type => {
        const selected = sales.bodyType && sales.bodyType.uri === type.uri;
        const select   = () => sales.setBodyType(selected ? undefined : type);
        const Svg      = type.svg;
        return <div className="option" key={type.uri}>
          <div className={`body-type ${selected?'selected':''}`} onClick={select}>
            <Svg/>
            <div className="dot"></div>
          </div>
        </div>
      }
  )}
  </div>

export default Sales.Consumer(Manufacturers)