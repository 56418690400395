import React from 'react'
import Sales from '../../../context/Sales'
import Key from '../../Calendar/Key'
import Calendar from '../Availability/Calendar'
import Controls from '../../Calendar/Controls'

const Availability = ({sales, product}) =>
  <div className="availability product-panel">
    <h3 className="title">Availability</h3>
    <div className="panel-body">
      <Controls
        transport={product?.availability?.transport}
        loadAvailability={sales.productAvailability}
        params={{uri: product?.uri}}
      />
      <div className="scroll-x scrollbar">
        <Calendar/>
      </div>
      <Key className="mar-t-2"/>
    </div>
  </div>

export default Sales.Consumer(Availability)
