const whiteStyle = {
  fill: "white",
  fillRule: "nonzero"
}

export const Celebrating = ({size="100%"}) =>
  <svg
    width={size} height={size} viewBox="0 0 151 151"
    style={{ fillRule:"evenodd", clipRule: "evenodd", strokeLinejoin:"round", strokeMiterlimit: 2 }}>
    <path
      className="fill-orange x-hover-fill-green"
      d="M149.824,86.73C143.539,127.861 105.1,156.109 63.969,149.824C22.837,143.539 -5.411,105.1 0.875,63.969C7.16,22.837 45.599,-5.411 86.73,0.875C127.861,7.16 156.109,45.599 149.824,86.73"
      style={{ fillRule: "nonzero" }}
    />
    <path
      id="star1"
      d="M53.181,31.707C53.083,31.822 52.949,31.908 52.795,31.945L47.053,33.348L46.597,39.241C46.573,39.558 46.348,39.824 46.038,39.899C45.729,39.974 45.407,39.842 45.24,39.572L42.131,34.544L36.385,35.932C36.076,36.006 35.753,35.874 35.587,35.603C35.42,35.332 35.446,34.985 35.652,34.742L39.473,30.232L36.377,25.196C36.21,24.925 36.237,24.578 36.442,24.335C36.648,24.093 36.987,24.01 37.281,24.131L43.244,26.573C43.62,26.727 43.8,27.156 43.646,27.531C43.492,27.908 43.063,28.087 42.688,27.933L38.808,26.345L41.003,29.916C41.17,30.186 41.144,30.533 40.938,30.776L38.229,33.974L42.303,32.99C42.612,32.915 42.934,33.047 43.101,33.318L45.306,36.883L45.629,32.704C45.653,32.387 45.878,32.122 46.187,32.047L50.259,31.052L46.384,29.453C46.09,29.332 45.908,29.036 45.931,28.719L46.411,22.293C46.442,21.888 46.794,21.584 47.199,21.614C47.604,21.644 47.907,21.997 47.877,22.402L47.437,28.297L52.901,30.552C53.195,30.673 53.378,30.97 53.354,31.287C53.342,31.446 53.279,31.592 53.181,31.707"
      style={whiteStyle}
    />
    <path
      id="star2"
      d="M46.968,67.862C46.842,67.779 46.741,67.656 46.685,67.508L43.885,60.086L35.96,60.434C35.643,60.448 35.352,60.257 35.239,59.958C35.128,59.661 35.22,59.325 35.468,59.126L41.662,54.169L38.881,46.74C38.77,46.442 38.862,46.106 39.111,45.908C39.36,45.709 39.708,45.694 39.974,45.868L46.601,50.227L52.809,45.287C53.057,45.089 53.405,45.073 53.671,45.248C53.936,45.423 54.059,45.749 53.974,46.056L51.736,54.22C51.629,54.612 51.224,54.842 50.833,54.735C50.44,54.628 50.211,54.223 50.319,53.832L51.962,47.839L47.1,51.709C46.851,51.906 46.504,51.922 46.238,51.747L41.047,48.334L43.225,54.153C43.337,54.451 43.244,54.786 42.996,54.984L38.145,58.867L44.352,58.594C44.669,58.579 44.961,58.772 45.072,59.069L47.265,64.882L48.924,58.894C49.008,58.588 49.281,58.371 49.598,58.356L58.054,57.962C58.46,57.944 58.804,58.256 58.823,58.662C58.842,59.067 58.528,59.411 58.123,59.43L50.199,59.8L48.081,67.445C47.996,67.751 47.723,67.968 47.406,67.983C47.247,67.99 47.094,67.945 46.968,67.862"
      style={whiteStyle}
    />
    <path
      id="star3"
      d="M109.084,68.597C108.976,68.483 108.903,68.335 108.882,68.172L107.821,60.005L99.729,58.47C99.405,58.409 99.157,58.146 99.114,57.817C99.073,57.49 99.245,57.172 99.544,57.031L106.983,53.497L105.943,45.327C105.901,45 106.075,44.682 106.373,44.541C106.672,44.4 107.027,44.467 107.254,44.707L112.913,50.69L120.362,47.176C120.661,47.035 121.016,47.102 121.243,47.342C121.469,47.582 121.516,47.941 121.357,48.231L117.151,55.947C116.95,56.318 116.486,56.454 116.116,56.253C115.744,56.051 115.609,55.588 115.812,55.217L118.899,49.553L113.064,52.305C112.766,52.446 112.411,52.379 112.184,52.14L107.752,47.454L108.567,53.853C108.609,54.18 108.435,54.497 108.137,54.638L102.311,57.406L108.648,58.609C108.972,58.67 109.22,58.933 109.262,59.26L110.094,65.657L113.196,60.001C113.355,59.711 113.681,59.557 114.005,59.618L122.644,61.233C123.059,61.312 123.332,61.709 123.254,62.124C123.177,62.538 122.778,62.811 122.364,62.733L114.268,61.22L110.308,68.44C110.149,68.73 109.822,68.884 109.498,68.823C109.335,68.792 109.192,68.711 109.084,68.597"
      style={whiteStyle}
    />
    <g id="celebrating">
      <path d="M22.566,90.144C21.548,90.825 20.342,91.059 18.95,90.847C17.228,90.583 15.958,89.824 15.141,88.568C14.325,87.307 14.065,85.71 14.36,83.776C14.68,81.686 15.487,80.16 16.781,79.199C17.906,78.362 19.222,78.059 20.728,78.289C22.743,78.597 24.115,79.483 24.845,80.947C25.246,81.768 25.408,82.555 25.332,83.308L22.83,82.925C22.755,82.326 22.613,81.861 22.403,81.53C22.027,80.94 21.393,80.577 20.499,80.441C19.589,80.302 18.815,80.559 18.178,81.213C17.54,81.866 17.119,82.865 16.913,84.208C16.708,85.551 16.832,86.6 17.285,87.354C17.738,88.107 18.392,88.55 19.248,88.68C20.125,88.815 20.838,88.63 21.386,88.126C21.688,87.856 21.97,87.425 22.232,86.833L24.711,87.212C24.294,88.484 23.58,89.462 22.566,90.144Z" style={whiteStyle}/>
      <path d="M34.963,87.598L29.146,86.709L28.675,89.788L35.305,90.802L34.976,92.955L25.901,91.568L27.731,79.592L36.514,80.934L36.189,83.055L29.852,82.086L29.464,84.629L35.281,85.518L34.963,87.598Z" style={whiteStyle}/>
      <path d="M38.587,81.251L41.089,81.633L39.588,91.456L45.519,92.362L45.19,94.515L36.757,93.227L38.587,81.251Z" style={whiteStyle}/>
      <path d="M55.887,90.796L50.07,89.907L49.599,92.986L56.229,93.999L55.9,96.152L46.825,94.765L48.655,82.789L57.438,84.131L57.114,86.252L50.776,85.284L50.388,87.827L56.205,88.716L55.887,90.796Z" style={whiteStyle}/>
      <path d="M63.149,97.26L57.722,96.43L59.552,84.455L65.369,85.343C66.834,85.589 67.809,86.174 68.294,87.096C68.585,87.661 68.676,88.299 68.567,89.008C68.455,89.74 68.181,90.299 67.745,90.687C67.501,90.905 67.163,91.086 66.731,91.23C67.309,91.546 67.717,91.969 67.955,92.498C68.193,93.028 68.259,93.637 68.154,94.325C68.045,95.034 67.769,95.643 67.326,96.152C67.041,96.491 66.709,96.762 66.329,96.964C65.899,97.198 65.415,97.329 64.876,97.357C64.337,97.386 63.761,97.353 63.149,97.26ZM63.898,91.978L60.916,91.522L60.428,94.715L63.369,95.165C63.895,95.245 64.314,95.237 64.628,95.141C65.199,94.962 65.541,94.505 65.653,93.768C65.748,93.145 65.556,92.678 65.077,92.366C64.81,92.192 64.417,92.063 63.898,91.978ZM65.49,89.885C65.845,89.74 66.061,89.415 66.137,88.911C66.223,88.353 66.062,87.952 65.656,87.707C65.302,87.525 64.835,87.39 64.255,87.302L61.623,86.899L61.219,89.54L64.16,89.989C64.686,90.07 65.129,90.035 65.49,89.885Z" style={whiteStyle}/>
      <path d="M75.332,94.316L72.748,93.921L72.031,98.617L69.585,98.243L71.415,86.267L77.29,87.165C78.127,87.31 78.757,87.511 79.18,87.77C79.603,88.029 79.944,88.366 80.202,88.782C80.416,89.125 80.57,89.493 80.665,89.884C80.761,90.276 80.772,90.707 80.7,91.178C80.614,91.747 80.385,92.284 80.013,92.79C79.642,93.296 79.109,93.612 78.413,93.739C78.932,94.045 79.275,94.421 79.443,94.865C79.611,95.309 79.63,95.951 79.502,96.79L79.379,97.595C79.295,98.142 79.26,98.516 79.274,98.718C79.292,99.036 79.409,99.287 79.625,99.47L79.579,99.77L76.824,99.35C76.789,99.073 76.768,98.85 76.76,98.683C76.746,98.337 76.764,97.988 76.812,97.635L76.966,96.519C77.072,95.754 77.01,95.223 76.781,94.928C76.552,94.632 76.069,94.428 75.332,94.316ZM77.184,92.37C77.717,92.219 78.035,91.808 78.138,91.136C78.249,90.41 78.082,89.886 77.638,89.563C77.389,89.381 76.993,89.248 76.452,89.166L73.543,88.721L73.051,91.938L75.887,92.372C76.45,92.458 76.883,92.457 77.184,92.37Z" style={whiteStyle}/>
      <path d="M88.764,98.655L84.352,97.981L83.147,100.316L80.531,99.916L86.635,88.593L89.462,89.025L91.873,101.649L89.159,101.234L88.764,98.655ZM88.38,96.484L87.605,91.543L85.342,96.02L88.38,96.484Z" style={whiteStyle}/>
      <path d="M103.755,91.209L103.431,93.33L99.848,92.782L98.342,102.638L95.823,102.253L97.329,92.397L93.73,91.847L94.054,89.727L103.755,91.209Z" style={whiteStyle}/>
      <path d="M104.853,91.377L107.339,91.757L105.509,103.733L103.023,103.353L104.853,91.377Z" style={whiteStyle}/>
      <path d="M117.357,105.543L114.854,105.161L111.263,95.907L109.963,104.413L107.631,104.057L109.461,92.081L112.086,92.482L115.577,101.571L116.855,93.211L119.187,93.567L117.357,105.543Z" style={whiteStyle}/>
      <path d="M126.909,106.704C126.186,107.009 125.343,107.088 124.378,106.941C122.791,106.698 121.575,105.95 120.731,104.695C119.843,103.429 119.546,101.837 119.839,99.92C120.135,97.98 120.906,96.507 122.152,95.501C123.398,94.494 124.898,94.124 126.653,94.392C128.175,94.625 129.339,95.198 130.145,96.111C130.95,97.024 131.333,98.067 131.292,99.242L128.83,98.866C128.765,98.019 128.39,97.377 127.703,96.939C127.318,96.697 126.874,96.538 126.371,96.461C125.406,96.314 124.559,96.557 123.827,97.191C123.096,97.824 122.618,98.873 122.395,100.335C122.169,101.808 122.346,102.902 122.924,103.617C123.502,104.332 124.219,104.754 125.075,104.885C125.914,105.014 126.639,104.876 127.25,104.473C127.86,104.071 128.287,103.486 128.532,102.72L125.762,102.296L126.067,100.298L131.056,101.06L130.073,107.487L128.416,107.233L128.393,105.7C127.824,106.195 127.329,106.53 126.909,106.704Z" style={whiteStyle}/>
    </g>
    <g id="years">
      <path d="M26.493,114.142L23.495,113.684L25.064,103.417L21.563,102.882L21.867,100.89C22.797,100.99 23.454,101.027 23.836,101.001C24.444,100.961 24.963,100.774 25.392,100.441C25.686,100.213 25.932,99.886 26.13,99.461C26.247,99.205 26.315,99.013 26.334,98.883L28.768,99.254L26.493,114.142Z" style={whiteStyle}/>
      <path d="M36.716,108.139C36.36,108.085 36.045,108.082 35.772,108.131C35.288,108.226 34.896,108.477 34.595,108.887L31.986,108.362L34.29,100.298L42.493,101.552L42.113,104.036L36.025,103.106L34.994,106.279C35.49,106.054 35.873,105.912 36.141,105.855C36.589,105.763 37.118,105.763 37.727,105.856C38.959,106.044 39.97,106.623 40.761,107.591C41.552,108.559 41.826,109.834 41.585,111.415C41.374,112.791 40.745,113.952 39.697,114.899C38.649,115.845 37.245,116.184 35.486,115.915C34.069,115.699 32.963,115.141 32.169,114.242C31.375,113.343 31.033,112.188 31.144,110.776L34.06,111.222C34.079,111.876 34.226,112.401 34.502,112.797C34.778,113.193 35.23,113.439 35.86,113.535C36.586,113.646 37.177,113.475 37.635,113.023C38.093,112.572 38.381,111.959 38.499,111.185C38.615,110.426 38.535,109.757 38.259,109.179C37.984,108.601 37.469,108.254 36.716,108.139Z" style={whiteStyle}/>
      <path d="M63.38,104.291L56.875,112.964L56.006,118.652L52.843,118.169L53.712,112.481L49.92,102.235L53.647,102.804L55.781,109.876L59.797,103.744L63.38,104.291Z" style={whiteStyle}/>
      <path d="M74.169,114.661L66.818,113.538L66.224,117.429L74.602,118.709L74.186,121.43L62.717,119.677L65.03,104.544L76.129,106.24L75.719,108.919L67.711,107.696L67.22,110.909L74.571,112.033L74.169,114.661Z" style={whiteStyle}/>
      <path d="M85.737,120.011L80.161,119.16L78.639,122.11L75.333,121.605L83.046,107.297L86.619,107.843L89.666,123.795L86.237,123.271L85.737,120.011ZM85.252,117.269L84.273,111.025L81.412,116.682L85.252,117.269Z" style={whiteStyle}/>
      <path d="M98.671,119.099L95.406,118.6L94.499,124.534L91.408,124.062L93.721,108.928L101.144,110.062C102.202,110.245 102.998,110.5 103.533,110.827C104.068,111.154 104.499,111.58 104.824,112.106C105.094,112.54 105.29,113.004 105.41,113.499C105.531,113.993 105.545,114.538 105.454,115.134C105.345,115.853 105.055,116.532 104.586,117.171C104.117,117.81 103.443,118.21 102.564,118.37C103.22,118.757 103.653,119.231 103.865,119.793C104.077,120.354 104.102,121.165 103.94,122.226L103.785,123.242C103.679,123.934 103.635,124.407 103.652,124.661C103.674,125.064 103.822,125.381 104.095,125.612L104.037,125.992L100.556,125.46C100.512,125.11 100.485,124.829 100.475,124.617C100.458,124.18 100.48,123.739 100.541,123.293L100.735,121.883C100.869,120.916 100.791,120.246 100.501,119.872C100.212,119.499 99.601,119.241 98.671,119.099ZM101.011,116.64C101.685,116.449 102.086,115.929 102.216,115.08C102.356,114.163 102.146,113.501 101.585,113.093C101.27,112.862 100.77,112.695 100.085,112.59L96.41,112.029L95.788,116.094L99.372,116.642C100.083,116.751 100.63,116.75 101.011,116.64Z" style={whiteStyle}/>
      <path d="M112.099,124.986C112.845,125.1 113.464,125.11 113.954,125.017C114.885,124.837 115.416,124.319 115.547,123.464C115.623,122.964 115.464,122.544 115.067,122.203C114.67,121.869 114.018,121.528 113.112,121.179L111.565,120.575C110.045,119.979 109.016,119.426 108.477,118.916C107.564,118.062 107.221,116.892 107.448,115.407C107.655,114.052 108.32,113.001 109.443,112.255C110.565,111.509 112.082,111.282 113.991,111.574C115.586,111.817 116.882,112.448 117.879,113.465C118.876,114.483 119.278,115.8 119.087,117.417L116.048,116.952C116.133,116.034 115.834,115.326 115.154,114.83C114.7,114.501 114.106,114.281 113.374,114.169C112.56,114.045 111.884,114.11 111.348,114.364C110.812,114.618 110.499,115.04 110.409,115.628C110.326,116.169 110.504,116.609 110.942,116.95C111.223,117.175 111.848,117.484 112.818,117.877L115.33,118.902C116.431,119.35 117.238,119.848 117.75,120.396C118.544,121.246 118.839,122.345 118.633,123.693C118.421,125.076 117.717,126.144 116.52,126.896C115.323,127.648 113.759,127.876 111.829,127.582C109.858,127.28 108.376,126.593 107.384,125.521C106.392,124.448 106.017,123.124 106.257,121.55L109.276,122.011C109.266,122.717 109.375,123.263 109.604,123.648C110.022,124.349 110.854,124.795 112.099,124.986Z" style={whiteStyle}/>
    </g>
    <path id="glass" d="M91.54,46.636C90.573,49.479 89.532,52.3 88.422,55.09C86.256,60.531 85.124,66.163 85.101,72.017C85.099,72.56 84.988,72.848 84.349,72.961C79.699,73.789 75.153,73.438 70.725,71.802C69.538,71.364 68.415,70.754 67.259,70.23C66.902,70.068 66.865,69.855 66.981,69.482C68.878,63.398 69.421,57.185 68.831,50.844C68.484,47.122 68.252,43.383 68.606,39.647C68.963,35.882 69.489,32.148 70.436,28.497L85.554,30.808C86.067,30.886 86.546,30.533 86.625,30.02C86.703,29.507 86.351,29.027 85.838,28.949L70.949,26.674C71.149,26.01 71.363,25.349 71.597,24.692C71.758,24.239 71.95,24.138 72.408,24.21C75.922,24.765 79.44,25.295 82.956,25.832C86.432,26.363 89.905,26.907 93.384,27.412C93.912,27.489 94.068,27.683 94.076,28.201C94.085,28.87 94.085,29.54 94.076,30.208L90.85,29.715C90.337,29.637 89.858,29.989 89.779,30.502C89.701,31.015 90.053,31.495 90.566,31.574L94.018,32.101C93.949,33.616 93.821,35.129 93.597,36.637C93.097,40.009 92.642,43.396 91.54,46.636M96.562,27.821C96.499,26.134 95.755,25.253 94.075,24.996C90.496,24.447 86.917,23.901 83.338,23.354C79.676,22.795 76.015,22.232 72.352,21.677C70.898,21.457 69.818,22.064 69.394,23.469C68.664,25.886 67.888,28.3 67.351,30.764C65.86,37.599 65.644,44.508 66.337,51.466C66.69,55.008 66.724,58.572 66.077,62.088C65.635,64.489 64.995,66.853 64.446,69.234C64.145,70.538 64.519,71.48 65.639,72.189C66.044,72.445 66.462,72.683 66.892,72.895C70.811,74.835 74.953,75.882 79.332,75.892C81.482,75.897 83.635,75.817 85.714,75.165C86.95,74.777 87.542,73.943 87.592,72.664C87.661,70.952 87.714,69.237 87.866,67.532C88.298,62.712 89.879,58.205 91.64,53.738C92.68,51.1 93.708,48.432 94.417,45.694C95.931,39.846 96.788,33.893 96.562,27.821" style={whiteStyle}/>
  </svg>

export default Celebrating