import React from 'react'
import Sales from '../context/Sales'
import Auth from '../context/Auth'
import URLS  from '../config/URLS'
import { DropdownMenu, Icon, Link } from '@abw/badger-ui'
import { useNavigate } from 'react-router'
import { inflect } from '../utils'

const Controls = ({Auth, user, basket}) => {
  const navigate = useNavigate();
  return (
    <div className="controls debug-orange">
      <div className="control">
        <Link to={URLS.search} label="Product search">
          <Icon name="magnify2" className="x2 blue"/>
        </Link>
        <div className="caption">Search</div>
      </div>
      <div className="control">
        <Link to={URLS.basket.home} label="Your basket">
          <Icon name="basket" className="x2 green"/>
        </Link>
        <div className="caption">Your Basket ({basket?.items?.length ? inflect(basket?.items?.length, 'item') : 'empty'})</div>
        { false && basket?.items?.length
          ? <div className="count">{basket.items.length}</div>
          : null
        }
      </div>
      { user
        ? <UserDropdown Auth={Auth} user={user} goHome={() => navigate(URLS.home)} className="usermenu"/>
        : <div className="control">
            <Link to={URLS.auth.login} label="User login">
              <Icon name="user" className="x2 yellow"/>
            </Link>
            <div className="caption">Login</div>
          </div>
      }
    </div>
  )
}

const UserDropdownTrigger = () => {
  const navigate = useNavigate();
  return <Icon name="user" className="x2" color="yellow" onClick={() => navigate(URLS.user.home)}/>
}

const UserDropdown = ({Auth, user, goHome}) =>
  <DropdownMenu
    right
    items={UserDropdownItems(Auth, user, goHome)}
    Trigger={UserDropdownTrigger}
  />

const UserDropdownItems = (Auth, user, goHome) => {
  let items = [
    {
      text: 'Your Account',
      iconLeft: "user",
      to: URLS.user.home,
    },
    {
      text: 'Hire it Again',
      iconLeft: "lens",
      to: URLS.user.products
    },
    {
      text: 'FAQs',
      iconLeft: "question-circle",
      to: URLS.about.faq
    },
  ];
  if (user.is_admin) {
    items.push({
      text: 'Administration',
      iconLeft: "cogs",
      href: URLS.admin.home
    });
    items.push({
      text: 'Style Guide',
      iconLeft: "palette",
      to: URLS.styles.home
    });
    items.push({
      text: 'Turn Debugging ' + (Auth.debugging ? 'Off' : 'On'),
      iconLeft: "wrench",
      onClick: Auth.toggleDebugging
    });
  }
  items.push({
    text: 'Logout',
    iconLeft: 'logout',
    onClick: () => Auth.logoutUser(() => goHome)
  });
  return items;
}

export default Sales.Consumer(
  Auth.Consumer(Controls)
);