import React from 'react'
import Sales from '../../../context/Sales'
import Pictures from '../Pictures'
import PriceAdd from '../PriceAdd'
import { Loader } from '@abw/badger-ui'

const PicturesPanel = ({sales, product}) =>
  <div className="product-panel pictures relative">
    <div className="pictures-price-button">
      <Pictures/>
      { product.active
        ? <PriceAdd product={product} selectDays={true}/>
        : null
      }
      { sales.saving === 'add-product-' + product.uri
        ? <Loader bgColor="transparent" overlayColor="blackish" message="Adding to basket..." textSize="smallish"/>
        : null
      }
    </div>
  </div>

export default Sales.Consumer(PicturesPanel)

