import React from 'react'
import Auth from '../context/Auth'
import { Icon } from '@abw/badger-ui'
import { Link } from 'react-router-dom'
import URLS from '../config/URLS.jsx'
import { DebugIncomplete } from '../config/Site.jsx'

const IncompleteOrder = ({Auth, user}) =>
  (Boolean(user?.payable_order_id) || DebugIncomplete) &&
    <Link
      to={URLS.user.order(user?.payable_order_id)}
      className="orange button incomplete-order"
    >
      <Icon name="warning-ring" className="mar-r"/>
      You have an order in progress
      <Icon name="angle-right" className="mar-l"/>
    </Link>

export default Auth.Consumer(IncompleteOrder)