// Various components generate debugging messages via a this.debug()
// method added by the addDebug(this, enable, prefix, color) function
// in ../utils/Object.  The values for "enable", "prefix" and "color"
// are defined for various components below.
export const Debug = {
    // set the first argument to true for any of the below to see
    // context debugging in the JS console.
    context: {
      auth:     [false, "Auth",     "MediumVioletRed"],
      sales:    [false, "Sales",    "MediumVioletRed"],
    },
};

export default Debug