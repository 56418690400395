import React from 'react'
import Sales from '../../context/Sales'
import { price } from '../../utils'

const showFirst = {
  focal_length: true,
  fstop: true,
}

const Features = ({product}) =>
  <table className="product-features table">
    <tbody>
    { Object.keys(showFirst).map(
        key => product.features[key]
          ? <Feature key={key} feature={product.features[key]}/>
          : null
    )}
    { Object.entries(product.features).map(
        ([uri, feature]) => showFirst[uri]
          ? null
          : <Feature key={uri} feature={feature}/>
    )}
    { product.insurance_value
      ? <Feature feature={{ name: 'Insurance Value', value: price(product.insurance_value) }}/>
      : null
    }
    </tbody>
  </table>

const Feature = ({feature}) =>
  <tr>
    <th>{feature.name}</th>
    <td>{feature.value}</td>
  </tr>

export default Sales.Consumer(Features)
