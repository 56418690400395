import React from 'react'
import Sales from '../../context/Sales'
import { Button } from '@abw/badger-ui'
import { markupHTML } from '../../utils'
import { Warning } from '../../ui'

const CompatibilityNote = ({product}) =>
  <CompatibilityWarning compatibility={product.compatibility}/>

export const CompatibilityWarning = ({compatibility, iconOnRight=false}) => {
  const [open, setOpen] = React.useState(false);
  return open
    ? <Warning title="Compatibility note" icon="alert" className="mar-t-none">
        <div dangerouslySetInnerHTML={{__html:markupHTML(compatibility)}}/>
        <Button text="OK" className="subdued" color="orange" onClick={e => setOpen(false)}/>
      </Warning>
    : <span onClick={e => setOpen(true)}>
        <Warning
          className={`revealer ${iconOnRight ? 'icon-on-right' : ''}`}
          title="Compatibility note"
          icon="info"
          onClick=""
        />
      </span>
}

export default Sales.Consumer(CompatibilityNote)
