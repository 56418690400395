import React from 'react'
import BodyTypes from '../../config/BodyTypes'
import Sales from '../../context/Sales'
import Card from '../Product/Card'
import Manufacturers from './Manufacturers'
import { Icon, IconStack } from '@abw/badger-ui'
import { Carousel } from 'react-responsive-carousel'
import { markupHTML } from '../../utils'

export const LatestKit = ({sales}) => {
  if (! sales.ready) {
    return null;
  }
  const btu = sales.bodyType?.uri || 'canon';
  const bodyType = BodyTypes.find( t => t.uri === btu );
  const latest = sales.latestProducts[bodyType?.uri];

  return <div className="hero latest-kit darkish">
    <div className="container">
      <div className="pictures-carousel">
      <Manufacturers/>
      <LatestCarousel sales={sales} latest={latest} bodyType={bodyType}/>
    </div>
    </div>
  </div>
}

const LatestCarousel = ({sales, latest, bodyType}) =>
  <Carousel emulateTouch={true} showThumbs={false} showStatus={false} autoPlay={true}
    renderArrowPrev={(onClickHandler, hasPrev) => <PrevArrow onClick={onClickHandler} hasPrev={hasPrev}/>}
    renderArrowNext={(onClickHandler, hasNext) => <NextArrow onClick={onClickHandler} hasNext={hasNext}/>}
  >
    { latest.map(
        product =><div key={product.id} className="carousel-item">
          <LatestProduct sales={sales} product={product} bodyType={bodyType}/>
        </div>
    )}
  </Carousel>

export const PrevArrow = ({onClick, hasPrev}) =>
  <div className={`prev nav ${hasPrev ? 'active' : ''}`} onClick={onClick}>
    <Icon name="angle_left" className="x4"/>
  </div>

export const NextArrow = ({onClick, hasNext, ...props}) =>
  <div className={`next nav ${hasNext ? 'active' : ''}`} onClick={onClick}>
    <Icon name="angle_right" className="x4"/>
  </div>

const LatestProduct = ({product, bodyType}) => {
  const Svg = bodyType.svg;

  return <div className="product-preview">
    <div className="new">
      <IconStack
        backIcon="disc" backColor="violet"
        foreIcon="new_left" foreColor="white"
      />
    </div>
    <div className="quote">
      <div className="blurb" dangerouslySetInnerHTML={{__html:markupHTML(product.headline)}}/>
      <div className="details">
        <div className={`body-type ${bodyType.uri}`}>
          { Svg
            ? <Svg/>
            : null
          }
        </div>
        <div className="lens-name">{product.name}</div>
      </div>
    </div>
    <div className="product-pic">
      <Card product={product} showTitle={false} showNew={false} size={600}/>
    </div>
  </div>
}

export default Sales.Consumer(LatestKit)