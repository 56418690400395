import React from 'react'
import Sales from '../../context/Sales'
import BodyTypes from '../../config/BodyTypes'
import LensMount from './LensMount';
import LensMounts from '../../config/LensMounts'

const BodyType = ({sales}) =>
  <>
    <h4 className="mar-v-none smallish">Camera system</h4>
    <div className="body-types options">
    { BodyTypes.map(
        type => {
          const selected = sales.bodyType && sales.bodyType.uri === type.uri;
          const select   = () => sales.setBodyType(selected ? undefined : type);
          const Svg      = type.svg;
          const mounts   = LensMounts[type.uri];
          return <div className="option" key={type.uri}>
            <div className={`body-type item ${selected?'selected':''}`} onClick={select}>
              <Svg/>
            </div>
            { selected && mounts
              ? <LensMount/>
              : null
            }

          </div>
        }
    )}
    </div>
  </>

export default Sales.Consumer(BodyType)