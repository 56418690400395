import React from 'react'
import Sales from '../../../context/Sales'
import Head from '../../Calendar/Head';
import { Icon } from '@abw/badger-ui';

const Calendar = ({product, className=''}) => {
  const avail  = product?.availability;
  const months = avail?.months;
  if (! months) {
    return null;
  }
  return <table className={`calendar calendar-today ${className}`}>
    <Head calendar={avail}/>
    <tbody>
      <tr className="availability">
        { months.map(
            month => month.days.map(
              day => <td
                key={`${month.year}-${month.month}-${day.day}-qty`}
                className={`day ${day.flags}`}
              >
                <Icon name={day.past ? 'ban' : day.lots ? 'check' : day.some ? 'limited' : 'times'}/>
              </td>
            )
        )}
      </tr>
    </tbody>
  </table>
}

export default Sales.Consumer(Calendar)

