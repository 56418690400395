import React from 'react'
import Auth from '../context/Auth'
import Cookies from './Cookies'
import Footer from './Footer'
import Header from './Header'

const Layout = ({Auth, children}) =>
  <div className={`app ${Auth.debugging ? 'debugging' : ''}`}>
    <Header/>
    <main className="site">
      {children}
    </main>
    <Footer/>
    <Cookies/>
  </div>

export default Auth.Consumer(Layout)