import React from 'react'
import Sales from '../../context/Sales'
import { Button } from '@abw/badger-ui';

const Controls = ({sales, transport, loadAvailability, params, className=''}) => {
  const loading   = sales.loading || '';
  if (! transport) {
    return null;
  }
  return <div className="calendar-controls">
    <div className="date-control backward">
      { transport.last_month &&
          <Button
            iconLeft={loading === 'last-month' ? 'aperture' : 'angles-left'}
            color={loading === 'last-month' ? 'green' : 'blue'}
            size="smallish"
            text="Month"
            className="subdued month"
            onClick={() => loadAvailability({
              ...params,
              ...transport.last_month,
              loading: "last-month"
            })}
          />
      }
      { transport.last_week &&
          <Button
            iconLeft={loading === 'last-week' ? 'aperture' : 'angle-left'}
            color={loading === 'last-week' ? 'green' : 'blue'}
            size="smallish"
            className="subdued week"
            text="Week"
            onClick={() => loadAvailability({
              ...params,
              ...transport.last_week,
              loading: "last-week"
            })}
          />
      }
    </div>
    <div className="text-center month-select">
    { transport.months &&
      <select
        className="input select"
        value={transport.month}
        onChange={
          e => e.target.value
            ? loadAvailability({
                ...params,
                month: e.target.value,
                loading: "month"
              })
            : undefined
      }>
        <option value="" disabled>Select month</option>
        { transport.months.map(
            month => <option key={`${month.year}-${month.month}`} value={`${month.year}-${month.month}`}>{month.text}</option>
        )}
      </select>
    }
    </div>
    <div className="date-control forward">
      { transport.next_week &&
          <Button
            iconRight={loading === 'next-week' ? 'aperture' : 'angle-right'}
            color={loading === 'next-week' ? 'green' : 'blue'}
            size="smallish"
            className="subdued week"
            text="Week"
            onClick={() => loadAvailability({
              ...params,
              ...transport.next_week,
              loading: "next-week"
            })}
          />
      }
      { transport.next_month &&
          <Button
            iconRight={loading === 'next-month' ? 'aperture' : 'angles-right'}
            color={loading === 'next-month' ? 'green' : 'blue'}
            size="smallish"
            className="subdued month"
            text="Month"
            onClick={() => loadAvailability({
              ...params,
              ...transport.next_month,
              loading: "next-month"
            })}
          />
      }
    </div>
  </div>

}

export default Sales.Consumer(Controls)

