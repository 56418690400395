import React from 'react'
import Sales from '../../context/Sales'
import Card from '../Product/Card'
import { Button } from '@abw/badger-ui'

const RecentlyViewed = ({sales}) =>
  sales.ready && sales.recent?.length
    ? <div className="mar-t-4 recently-viewed">
        <div className="flex end space">
          <h3 className="mar-v-none">Recently Viewed Products</h3>
          <Button text="Clear List" className="solid red small mar-b-none" iconRight="trash" onClick={sales.clearRecent}/>
        </div>
        <div className="browse-results">
          <div className="product-grid">
          { sales.recent.map(
              product => <Card key={product.id} product={product}/>
          )}
          </div>
        </div>
      </div>
    : null

export default Sales.Consumer(RecentlyViewed)