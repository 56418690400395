import contactTypes from "../config/ContactTypes";
import { prepareProduct } from "./Sales";

export function prepareUser(data) {
  let user = data.user;
  if (user) {
    prepareUserData(user);
  }
  let state = { user };
  if (data.account) {
    state.account = data.account;
  }
  if (data.order) {
    state.order = data.order;
  }
  return state;
}

export function prepareUserData(user) {
  user.addresses = user.addresses || [ ];
  user.contacts  = user.contacts  || { };
  prepareUserContacts(user.contacts);
  prepareUserProducts(user.hired_products);
}

export function prepareUserContacts(contacts) {
  contacts.social = [ ];
  Object.values(contactTypes)
    .filter(
      type => type.type !== "telephone"
    )
    .forEach(
      type => {
          const ct = contacts[type.type];
          if (ct && ct.length) {
            contacts.social.push(...ct);
          }
      }
    );
}

export function prepareUserProducts(products) {
  products.forEach(
    p => prepareProduct(p)
  )
}