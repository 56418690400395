import { splitToList } from './Misc'

// utility function to add a .debug() method to an object which either calls
// console.log() or does nothing, depending on the state of the debug flag
export function Debugger(flag, prefix='', color='green') {
  return flag
    ? prefix
      ? (format, ...args) => console.log('%c' + prefix + ': %c' + format, `color: ${color}`, 'color:black', ...args)
      : console.log.bind(console)
    : () => (undefined);
}

// utility function to add a .debug() method to an object which either calls
// console.log() or does nothing, depending on the state of the debug flag
export function addDebug(obj, flag, prefix='', color='green') {
  obj.debug = Debugger(flag, prefix, color);
}

// utility function to add a .log() method to an object which calls console.log
export function addLog(obj, flag=1) {
  obj.log = flag
    ? console.log.bind(console)
    : () => (undefined);
}


// utility function which applies a function to the values of an object
// and returns a new object
export function objMap(obj, fn) {
  return Object.keys(obj).reduce(
    (result, key) => {
      result[key] = fn(obj[key], key)
      return result
    },
    {}
  );
}

export function objWithKeys(obj, accept) {
  return Object.keys(obj).filter( r => accept(r) ).reduce(
    (result, key) => {
      result[key] = obj[key];
      return result;
    },
    {}
  );
}

export function objWithoutKeys(obj, remove) {
  return objWithKeys(obj, r => ! remove(r));
}

// utility function which takes an object ("that") and a list of method names
// and then creates a handlers object containing callable functions for each
// of the methods that are pre-bound to the object.  The names can be
// specified as an Array of strings, e.g. ['foo', 'bar'], or as a single
// string of whitespace delimited method names, e.g. 'foo bar')
export function bindHandlers(that, names) {
  let handlers = { };
  splitToList(names).forEach(
    name => {
      let method = that[name];
      if (method) {
        handlers[name] = method.bind(that);
      }
      else {
        throw new Error("Cannot bind to " + name + " method (not found in " + that + ")");
      }
    }
  );
  return handlers;
}

// request handler for a context object
export function contextRequest(endpoint, params) {
  return endpoint(params).then(this.response);
}

// response handler for a context object
export function contextResponse(json) {
  //console.log('Context response: ', json);
  if (json.ok) {
    //console.log('Context response OK: ', json.message);
    return json.data;
  }
  else {
    let error = json.message.toString();
    //console.log('Context response ERROR: ', error);
    this.setState({
      loading: false,
      saving:  false,
      error:   error,
    });
    return Promise.reject( error );
  }
}

// utility function which adds the above context request/response
// methods to the object passed as an argument
export function addAPIHandlers(that) {
  that.request  = contextRequest.bind(that);
  that.response = contextResponse.bind(that);
}