import React from 'react'
import API from '../utils/API';
import Debug from '../config/Debug';
import Generator from '@abw/react-context-generator'
import { bindHandlers, addDebug, addAPIHandlers } from '../utils/Object'
import { prepareUser } from '../utils/Auth'
import { doNothing } from '../utils';

const State = {
    ready:      false,
    products:   undefined,
    prices:     undefined,
    basket:     { },
    searchText: "",
    debugging:  0,
    logo:       'lfh-round-logo.svg',
};

class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...State };
        this.handlers = bindHandlers(
            this,
            'loadUser loadedUser logoutUser registering recovering cancel ' +
            'addContact deleteContact gdprConsent ' +
            'loadAccount loadOrder deactivateAddress toggleDebugging setLogo'
        );
        addAPIHandlers(this);
        addDebug(this, ...Debug.context.auth);
    }
    componentDidMount() {
        this.loadUser();
    }

    //-----------------------------------------------------------------------------
    // load user data
    //-----------------------------------------------------------------------------
    loadUser() {
        this.debug("loading user...");
        this.setState({ loading: true });
        this.request(API.auth.status)
            .then( data => this.loadedUser(data) );
    }
    loadedUser(data, callback) {
        this.debug("loadedUser: ", data);
        const state = prepareUser(data);
        this.setState(
          {
            loading: false,
            saving:  false,
            ready:   true,
            error:   false,
            ...state
          },
          callback
        );
    }
    logoutUser(callback) {
        this.debug("logoutUser()");
        API.auth.logout()
            .then( response => this.loadedUser(response.data, callback) )
            .catch( response => this.error(response) );
    }
    cancel(id, callback) {
        this.debug("cancel(%s) [callback:%o]", id, callback);
        API.auth.cancel({ id })
            .then(callback || doNothing)
            .catch( response => this.error(response) );
    }
    registering(data) {
        this.debug("registering()", data);
        this.setState({
          registrationEmail: data.email,
        });
    }
    recovering(data) {
        this.debug("recovering()", data);
        this.setState({
          recoveryEmail: data.email,
        });
    }

    //-----------------------------------------------------------------------------
    // Contacts
    //-----------------------------------------------------------------------------
    addContact(params, callback) {
        this.debug("addContact(%o)", params);
        this.setState({ saving: 'add_contact-' + params.type });
        return API.user.add_contact(params)
            .then( response => this.loadedUser(response.data, callback) )
            .catch( response => this.error(response) );
    }
    deleteContact(contact_id, callback) {
        this.debug("deleteContact(%s)", contact_id);
        this.setState({ saving: 'contact-' + contact_id });
        API.user.delete_contact({ contact_id })
            .then( response => this.loadedUser(response.data, callback) )
            .catch( response => this.error(response) );
    }

    //-----------------------------------------------------------------------------
    // Address
    //-----------------------------------------------------------------------------
    deactivateAddress(address_id, callback) {
        this.debug("deactivateAddress(%s)", address_id);
        this.setState({ saving: 'address-' + address_id });
        API.user.deactivate_address({ address_id })
            .then( response => this.loadedUser(response.data, callback) )
            .catch( response => this.error(response) );
    }

    //-----------------------------------------------------------------------------
    // GDPR consent
    //-----------------------------------------------------------------------------
    gdprConsent(gdpr_consent) {
        this.debug("gdprConsent(%s)", gdpr_consent);
        this.setState({ saving: 'gdpr_consent' });
        API.user.gdpr_consent({ gdpr_consent })
            .then( response => this.loadedUser(response.data) )
            .catch( response => this.error(response) );
    }

    //-----------------------------------------------------------------------------
    // Account
    //-----------------------------------------------------------------------------
    loadAccount() {
        this.debug("loading account...");
        this.setState({ loading: 'account' });
        API.user.account()
            .then( response => this.loadedAccount(response.data) )
            .catch( response => this.error(response) );
    }
    loadOrder(order_id) {
        this.debug("loadOrder(%s)", order_id);
        this.setState({ loading: 'order-' + order_id });
        API.user.order({ order_id })
            .then( response => this.loadedAccount(response.data) )
            .catch( response => this.error(response) );
    }
    loadedAccount(data, callback) {
        this.debug("loadedAccount: ", data);
        this.loadedUser(data, callback);
    }

    //-----------------------------------------------------------------------------
    // Debugging
    //-----------------------------------------------------------------------------
    toggleDebugging() {
      this.setState({ debugging: ! this.state.debugging });
    }
    setLogo(logo) {
      this.setState({ logo });
    }

    //-----------------------------------------------------------------------------
    // error handling
    //-----------------------------------------------------------------------------
    error(response) {
        this.debug("auth context error: ", response);
        this.setState({
            error: response.message,
            saving: false,
            loading: false,
        });
    }

    //-----------------------------------------------------------------------------
    // render
    //-----------------------------------------------------------------------------
    render() {
        let context = {
            ...this.props,
            ...this.state,
            ...this.handlers
        };
        // call the render prop passing an auth object containing
        // all the properties for this component, all the state,
        // and all of the callable handler functions.
        return this.props.render({
            Auth: context,
            user: context.user,
        });
    }
}

export default Generator(Auth);
