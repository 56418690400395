import { isUndefined } from './Misc'

export function classNames(props, ...more) {
  let classes = [ ];
  ['color','size','className'].forEach(
    prop => {
      if (props[prop]) {
        classes.push(props[prop]);
      }
    }
  );
  if (more) {
    more.forEach( m => classes.push(m) );
  }
  return classes.join(' ');
}

export function propClasses(tests, ...more) {
    let classes = [ ];
    Object.keys(tests).forEach(
        cls => tests[cls] && classes.push(cls)
    );
    if (more) {
        more.forEach( m => classes.push(m) );
    }
    return classes.join(' ');
}

/* Utility function to inflect a string to the singular/plural form based on the
 * number of items in n
 *  e.g.
 *    inflect(0, 'cat')     # no cats
 *    inflect(1, 'cat')     # 1 cat
 *    inflect(2, 'cat')     # 2 cats
 * It uses the pluralise() function which is *VERY* primitive, because pluralising
 * words is notoriously difficult.  The third argument can be provided to define the
 * plural form for those cases where the default behaviour gets it wrong.
 *  e.g.
 *    inflect(0, 'child', 'children')     # no children
 *    inflect(1, 'child', 'children')     # 1 child
 *    inflect(2, 'child', 'children')     # 2 children
 * The optional 4th argument can be used to provide a different word for the case where
 * n is 0.  The default is "no".
 *    inflect(0, 'black', 'black', "none, none more")     # none, none more black
 */
export function inflect(n, singular, plural, no="no") {
    return (n ? commas(n) : no)
      + ' '
      + (n === 1 ? singular : (plural || pluralise(singular)));
}

export function pluralise(singular) {
    let found;

    if (singular.match(/(ss?|sh|ch|x)$/)) {
        // e.g. grass/grasses, lash/lashes, watch/watches, box, boxes
        return singular + 'es';
    }
    else if ((found = singular.match(/(.*?[^aeiou])y$/))) {
        // lily/lillies
        return found[1] + 'ies';
    }
    else if (singular.match(/([^s\d\W])$/)) {
        // cat/cats
        return singular + 's';
    }
    return singular;
}

export function commas(n) {
    if (isUndefined(n)) {
        return '';
    }
    var bits  = n.toString().split('.'),
        rgx   = /(\d+)(\d{3})/;

    while (rgx.test(bits[0])) {
        bits[0] = bits[0].replace(rgx, '$1,$2');
    }
    return bits.join('.');
}

export function price(money) {
    return money
        ? '£' + commas(parseFloat(money).toFixed(2))
        : '';
}

export function priceOrPOA(money) {
    return money
        ? '£' + commas(parseFloat(money).toFixed(2))
        : '£POA';
}

export function priceOrFree(money) {
    return money
        ? '£' + commas(parseFloat(money).toFixed(2))
        : 'FREE';
}

export function markupHTML(text, params={ introFirst:false }) {
    const markup = splitMarkup(text);
    var   output = [ ];

    markup.forEach(
        (m, ndx) => {
            if (m.type === 'p') {
                let attrs = (ndx === 0 && params.introFirst)
                    ? { class: 'intro' }
                    : { };
                output.push(
                    element(
                        'p',
                        attrs,
                        markover(m.text)
                    )
                );
            }
            else if (m.type === 'h') {
                output.push(
                    element(
                        'h' + (m.level || 4),
                        { },
                        markover(m.text)
                    )
                );
            }
            else if (m.type === 'ul') {
                output.push(
                    bulletMarkup(m.items)
                );
            }
            else {
                throw new Error("invalid markup fragment: " + m);
            }
        }
    );
    return output.join("\n");
}


export function splitMarkup(text) {
    const paras   = splitParas(text);
    let   markup  = [ ];
    let   bullets = [ ];
    let   match;

    paras.forEach(
        para => {
            if (para.match(/^\s*[*]\s*/)) {
                bullets.push(
                  para.replace(/^\s*[*]\s*/, '')
                );
            }
            else {
                if (bullets.length) {
                    markup.push(ul(bullets.slice()));
                    bullets = [ ];
                }
                if ((match = para.match(/^\s*=h(\d)\s*(.*)\s*/))) {
                    markup.push(h(match[2], match[1]));
                }
                else {
                    markup.push(p(para));
                }
            }
        }
    );
    if (bullets.length) {
        markup.push(ul(bullets.slice()));
    }
    return markup;
}

export function splitParas() {
    const text = Array.prototype.slice.call(arguments).join('');
    if (text.length === 0) {
        return [ ];
    };
    const rows = text.split(/\s*\n+\s*/).filter(
        item => item.length > 0
    );
    return rows;
}

export function bulletMarkup(list) {
    return element(
        'ul',
        { class: "bullet" },
        list.map(
            function(item) {
                var cls = "item";
                if (item.match(/^=/)) {
                    item = item.replace(/^=/, '');
                    cls = "heading item";
                }
                return element(
                    'li',
                    { class: cls },
                    markover(item)
                );
            }
        ).join("\n")
    );
}

export function ul(items) {
    return { type: 'ul', items };
}

export function p(text) {
    return { type: 'p', text };
}

export function h(text, level) {
    return { type: 'h', text, level };
}

export function element(etype, attrs, ebody) {
    return'<'
        + etype
        + (attrs
            ? attributes(attrs)
            : '')
        + (ebody
            ? '>' + ebody + '</' + etype + '>'
            : '></' + etype + '>');

}

export function attributes(attrs) {
    var name, value;
    var chunks = [ ];
    for (name in attrs) {
        value = attrs[name];
        chunks.push(name + '="' + value + '"');
    }
    return chunks.length
        ? ' ' + chunks.join(' ')
        : '';
}

export function markover(text, params) {
    if (text === undefined || text === null) {
        return text;
    }
    const regex = /\[([\w+-]+):([^[\]]+)\]/g;
    let runaway = 20;

    while (text.match(regex)) {
      if (runaway-- === 0) {
        return text;
      }
      text = text.replace(
        regex,
        function(match, type, head)  {
            return multi_marklet(type, head);
        }
      );
    }
    return text;
}

export function multi_marklet(type, text) {
    let bits  = text.split('|', 2);
    let types = type.split('+');
    let mark  = types.pop();
    let work  = marklet(mark, ...bits);
    while (types.length) {
        mark = types.pop();
        work = marklet(mark, work, bits[1]);
    }
    return work;
}

export function marklet(type, head, body) {
    let hm = HTMLMarklets[type];
    if (hm) {
        return hm(head, body);
    }
    const rest = body?.length ? `${head}|${body}` : head;
    return `{${type}:${rest}}`;
}

const linkMarket = (link, body) => element('a', { class: "link", href: link }, body)

const HTMLMarklets = {
    sub:     text => element('sub', { }, text),
    sup:     text => element('sup', { }, text),
    b:       text => element('b', { }, text),
    i:       text => element('i', { }, text),
    u:       text => element('u', { }, text),
    bold:    text => element('b', { }, text),
    italic:  text => element('i', { }, text),
    under:   text => element('u', { }, text),
    code:    text => element('code', { }, text),
    quote:   text => element('q', { }, text),
    strike:  text => element('s', { }, text),
    red:     text => element('span', { class: "red" }, text),
    orange:  text => element('span', { class: "orange" }, text),
    yellow:  text => element('span', { class: "yellow" }, text),
    green:   text => element('span', { class: "green" }, text),
    blue:    text => element('span', { class: "blue" }, text),
    violet:  text => element('span', { class: "violet" }, text),
    a:       linkMarket,
    link:    linkMarket,
    product: (uri, body) => {
        return element(
            'a',
            { class: "product-link",
              href: `/product/${uri}`
            },
            body
        )
    }
};


