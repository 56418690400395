import React from 'react'
import Sales from '../context/Sales'
import Xmas from '../promotion/xmas/index.jsx'
// import Closed from '../promotion/closed-march-2024/index.jsx'

const Notices = ({notices=[], page='home'}) => {
  return (
    <>
    { page === 'home' &&
      <Xmas/>
    }
    </>
  )
}

export default Sales.Consumer(Notices)