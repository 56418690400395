import React from 'react'
import Auth from './context/Auth'
import Sales from './context/Sales'
import Routes from './site/Routes'
import analytics from './site/Analytics'
import { AnalyticsProvider } from 'use-analytics'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { config } from "@fortawesome/fontawesome-svg-core";
import '@fortawesome/fontawesome-svg-core/styles.css'

// now import the CSS styles
import 'react-toastify/dist/ReactToastify.css';
import './styles/lfh-revamp.scss'
import ScrollToTop from './utils/Scroll';
import { Loading, Theme } from '@abw/badger-ui'
import LFHTheme from './config/Theme'

// Disable the automatic insertion of CSS into the head of the document.
config.autoAddCss = false;

const App = () => <>
  <ToastContainer position="bottom-center" hideProgressBar={true} autoClose={5000} newestOnTop closeOnClick={true} theme="dark"/>
  <React.Suspense fallback={<Loading overlayColor="black"/>}>
    <HelmetProvider>
      <Helmet>
        <title>Lenses for Hire</title>
      </Helmet>
      <AnalyticsProvider instance={analytics}>
        <Theme.Provider {...LFHTheme}>
          <Auth.Provider>
            <Sales.Provider>
              <Router>
                <ScrollToTop>
                  <Routes/>
                </ScrollToTop>
              </Router>
            </Sales.Provider>
          </Auth.Provider>
        </Theme.Provider>
      </AnalyticsProvider>
    </HelmetProvider>
  </React.Suspense>
</>

export default App;
