import React, { useEffect } from 'react'
import Layout from './Layout'
import Home from '../routes/Home'
import Search from '../routes/Search'
import Product from '../routes/Product'
import Test from '../routes/Test'
import { Routes, Route, useLocation } from 'react-router-dom';
import { SelectProduct } from './Selectors'
import { Loading, sleep } from '@abw/badger-ui'
import { NotFound } from '../pages/NotFound'
import { useAnalytics } from 'use-analytics'
import { getConsentAnalytics } from '../utils/Cookies'
import OrderPlaced from '../routes/OrderPlaced'

const Basket    = React.lazy(() => import("../routes/Basket"));
const About     = React.lazy(() => import("../routes/About"));
const User      = React.lazy(() => import("../routes/User"));
const Auth      = React.lazy(() => import("../routes/Auth"));
const Styles    = React.lazy(() => import("../pages/Styles"));
const Christmas = React.lazy(() => import("../pages/Christmas"));
const February  = React.lazy(() => import("../pages/February"));

const SiteRoutes = () => {
  const location = useLocation();
  const { page } = useAnalytics();

  // trigger page view when location changes...
  useEffect(
    () => {
      // ...only if we have a cookie indicating analytics consent
      if (getConsentAnalytics()) {
        //console.log('sending page view analytics event');
        // defer page event to allow Helmet to update page title
        sleep(100).then(page);
      }
    },
    [location, page]
  );

  return <Layout>
    <Routes>
      <Route path="" end                        element={<Home/>} />
      <Route path="index.html"                  element={<Home/>} />
      <Route path="*"                           element={<NotFound/>} />
      <Route path="search"                      element={<Search/>} />
      <Route path="order_placed"                element={<OrderPlaced/>} />
      <Route path="test"                        element={<Test/>} />
      <Route path="product/:uri"                element={<SelectProduct Component={Product}/>} />
      <Route path="auth/*"
        element={
          <React.Suspense fallback={<Loading overlayColor="black"/>}>
            <Auth />
          </React.Suspense>
        }
      />
      <Route path="about/*"
        element={
          <React.Suspense fallback={<Loading overlayColor="black"/>}>
            <About />
          </React.Suspense>
        }
      />
      <Route path="basket/*"
        element={
          <React.Suspense fallback={<Loading overlayColor="black"/>}>
            <Basket />
          </React.Suspense>
        }
      />
      <Route path="user/*"
        element={
          <React.Suspense fallback={<Loading overlayColor="black"/>}>
            <User />
          </React.Suspense>
        }
      />
      <Route path="styles/*"
        element={
          <React.Suspense fallback={<Loading overlayColor="black"/>}>
            <Styles />
          </React.Suspense>
        }
      />
      <Route path="christmas"
        element={
          <React.Suspense fallback={<Loading overlayColor="black"/>}>
            <Christmas />
          </React.Suspense>
        }
      />
      <Route path="feb2023"
        element={
          <React.Suspense fallback={<Loading overlayColor="black"/>}>
            <February />
          </React.Suspense>
        }
      />
    </Routes>
  </Layout>
}

export default SiteRoutes
