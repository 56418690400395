import React from 'react'
import { Icon } from '@abw/badger-ui';

const Socials = () =>
  <div className="socials row">
    <h2>Follow us</h2>
    <div className="icon-links">
      <a href="https://www.facebook.com/LensesForHire/">
        <Icon name="facebook"/>
        <div>Facebook</div>
      </a>
      <a href="https://www.instagram.com/lensesforhire/">
        <Icon name="instagram"/>
        <div>Instagram</div>
      </a>
      <a href="https://www.linkedin.com/in/richie-haymes-a81a8a49/">
        <Icon name="linkedin"/>
        <div>Linked In</div>
      </a>
    </div>
  </div>

export default Socials