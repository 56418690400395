export const ProductTypes = [
  { uri:      'lens',
    title:    'Lenses',
    icon:     'type_lens',
    image:    '/images/categories/standard-trans.png'
  },
  { uri:      'camera',
    title:    'Cameras',
    icon:     'type_camera',
    image:    '/images/categories/camera-trans.png'
  },
  { uri:      'extender',
    title:    'Extenders',
    icon:     'type_extender',
    image:    '/images/categories/extender-trans.png'
  },
  // lighting is now merged with accessories
  // { uri:      'lighting',
  //   title:    'Lighting',
  //   image:    '/images/categories/lighting-trans.png'
  // },
  { uri:      'accessory',
    title:    'Accessories',
    icon:     'type_accessories',
    image:    '/images/categories/accessory-trans.png'
  },
];

export let ProductType = { };
ProductTypes.forEach(
  pt => ProductType[pt.uri] = pt
)


export default ProductTypes