import { sleep } from "@abw/badger-ui";
import { GravatarBaseURL, GravatarSize, GravatarDefault } from "../config/Site";
import { toast } from 'react-toastify'

export function isUndefined(v) {
  return typeof v === 'undefined'
    || v === null;
};

export function isDefined(v) {
  return typeof v !== 'undefined'
    && v !== null;
}

export function areDefined(...args) {
  return args.every( arg => isDefined(arg) );
}

export function isString(value) {
  return typeof value === 'string';
}

export function isArray(value) {
  return Array.isArray(value);
}

export function doNothing() {
  // speak again Cordelia
}

export function preventDefault(callback, ...args) {
  return callback
    ? function(event) {
        event.preventDefault();
        event.stopPropagation();
        return callback(...args);
      }
    : () => null;
}

export function debounce(func, timeout=300){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(
      () => func.apply(this, args),
      timeout
    );
  };
}

export function iterator(list, render) {
  const max = list.length - 1;
  return list.map(
    (item, index) => {
      const first = index === 0;
      const last  = index === max;
      return render({item, index, first, last});
    }
  )
}

export function splitToList(value, splitter=/,\s*|\s+/) {
  return isString(value) ? value.split(splitter)
      :  isArray(value)  ? value
      :  [value];
};

export function splitToHash(value, splitter) {
  const list = splitToList(value, splitter);
  let hash  = { };
  list.forEach( item => hash[item] = true );
  return hash;
};

// NOTE: this works like the _.range() function in lodash (which in turn
// does the same thing as Array.slice() et al) in that it DOES NOT include
// the stop parameter.  e.g. range(1, 3) returns [1, 2]
export function range(start, stop) {
  // console.log("range(%s:%s, %s:%s)", start, typeof start, stop, typeof stop);
  return Array(stop - start).fill(start).map((x, y) => x + y);
}

export function validatePostcode(postcode) {
  return postcode.match(/^[a-z]{1,2}\d/i);
}

export const fakeSubmit = submit => sleep(5000).then(() => {
  // fake a valid server response
  return Promise.resolve({ status: 200 });
})

export const gravatarURL = (emailHash, size=GravatarSize) =>
  `${GravatarBaseURL}${emailHash}?d=${encodeURIComponent(GravatarDefault)}&s=${size}`

export function copyToClipboard(text) {
  var textField = document.createElement('textarea')
  textField.innerText = text
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove();
  toast.success("Copied search URL to clipboard");
}

