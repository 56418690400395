import React from 'react'
import URLS from '../../config/URLS'
import Sales from '../../context/Sales'
import BodyType from './BodyType'
import BrowseResults from './BrowseResults'
import ProductType from './ProductType'
import Search from './Search'
import RecentlyViewed from './RecentlyViewed'
import { getQueryParams } from '../../utils'

const Browse = ({ sales }) => {
  React.useEffect(
    () => {
      const params = getQueryParams();
      if (sales.ready && Object.keys(params).length) {
        window.history.replaceState({}, document.title, URLS.search);
        sales.parseQueryParams(params);
      }
    },
    [sales]
  );

  return <div className="browse">
    <Search/>
    <BodyType/>
    <ProductType/>
    { sales.browsing && <BrowseResults/> }
    <RecentlyViewed/>
  </div>
}

export default Sales.Consumer(Browse)
