import React from 'react'
import Sales from '../context/Sales'
import Auth from '../context/Auth'
import Trustpilot from '../components/Trustpilot'
import Controls from './Controls'
import URLS, { BASE, IMGS } from '../config/URLS'
import { Routes, Route } from 'react-router-dom';
import { useObserver } from '../utils'
import { Link } from '@abw/badger-ui'
import IncompleteOrder from './IncompleteOrder.jsx'

const Header = ({Auth, user, basket}) => {
  const [containerRef, isVisible] = useObserver({
    threshold: 0.9
  });
  return (
    <header
      ref={containerRef}
      className={`site container ${isVisible ? 'onscreen' : 'offscreen'}`}
    >
      <Routes>
        <Route path={`${BASE}/*`}>
          <Route index    element={<Logos Auth={Auth} className="debug-red home logo"/>}/>
          <Route path="*" element={<Logos Auth={Auth} className="debug-red logo"/>} />
        </Route>
      </Routes>
      <Trustpilot/>
      <Controls/>
      <IncompleteOrder/>
    </header>
  )
}

const Logos = ({Auth, className}) =>
  <Link to={URLS.home} className={className}>
    {/* <img src={`${IMGS}/logo/${Auth.logo || 'lfh-round-logo-2.svg'}`} alt="LFH Logo"/> */}
    <img src={`${IMGS}/logo/lfh-round-logo-2.svg`} width="120" height="120" alt="LFH Logo"/>
    <img src={`${IMGS}/logo/lfh-logo-black-text.svg`} className="print" alt="LFH Logo for Print"/>
  </Link>


export default Sales.Consumer(
  Auth.Consumer(Header)
);