import React, { useEffect, useState } from 'react'
import Sales from '../../context/Sales'
import { Revealable } from '@abw/badger-ui';

/*
  Data in basket.countdown:

  "countdown": {
    "next_workday": "2022-03-28",
    "date_tomorrow": "2022-03-26",
    "cutoff_time": "15:00:00",
    "holidays": {
      "2022-04-15": "Good Friday",
      "2022-04-18": "Easter Monday"
    },
    "next_next_workday": "2022-03-29",
    "tomorrow_is_workday": 0,
    "today_is_workday": 1,
    "cutoff_today": "17:30:00",
    "date_today": "2022-03-25"
  },
*/

const Countdown = ({basket, debug=false}) => {
  const countdown = basket.countdown;
  const [counter, setCounter] = useState(ComputeCounter(countdown));

  useEffect(() => {
    if (countdown) {
      const timerId = setInterval(
        () => setCounter(ComputeCounter(countdown)),
        1000
      );
      return () => clearInterval(timerId);
    }
  }, [countdown]);

  return countdown && counter?.ready
    ? <div className="text-center">
        <div className={`countdown ${counter.level}`}>
          <div className="overprompt">
            Want it {counter.receive}? You have
          </div>
          <div className="clock">
            { counter.days
              ? <>
                  <CountdownElement number={counter.days} caption="days"/>
                  <div className="countdown-separator">:</div>
                </>
              : null
            }
            { counter.days || counter.hours
              ? <>
                  <CountdownElement
                    number={counter.hours?.toString().padStart(2, '0')}
                    caption={counter.hours === 1 ? 'hour' : 'hours'}
                  />
                  <div className="countdown-separator">:</div>
                </>
              : null
            }
            <CountdownElement
              number={counter.minutes?.toString().padStart(2, '0')}
              caption={counter.minutes === 1 ? 'minute' : 'minutes'}
            />
            <div className="countdown-separator">:</div>
            <CountdownElement
              number={counter.seconds?.toString().padStart(2, '0')}
              caption={counter.seconds === 1 ? 'second' : 'seconds'}
            />
          </div>
          <div className="underprompt">
            to place your order for dispatch {counter.dispatch}
          </div>
        </div>
        { debug
          ? <Revealable title="Countdown Debugging" className="bg-white mar-t-2 mobile if-debugging">
              <p>
                Today is {countdown.date_today} which {countdown.today_is_workday ? 'is' : 'is not'} a workday.
                <br/>
                Tomorrow is {countdown.date_tomorrow} which {countdown.tomorrow_is_workday ? 'is' : 'is not'} a workday.
                <br/>
                The next workday is {countdown.next_workday}.
                <br/>
                The next workday after that is {countdown.next_next_workday}.
                <br/>
                The cutoff time is {countdown.cutoff_time}.
                <br/>
                The cutoff time today (in case a user has extended the cutoff time) is {countdown.cutoff_today}.
                <br/>
                The earliest dispatch is {counter.dispatch} for delivery {counter.receive}.
                <br/>
                It {counter.order_today ? 'is' : 'is not'} possible to order today.
                <br/>
                Now the time is {counter.now?.toLocaleString('en-GB')}
                <br/>
                Cutoff today: {counter.cutoff?.toLocaleString('en-GB')}
                <br/>
                {counter.total_secs} seconds / {counter.total_mins} minutes / {counter.total_hours} hours / {counter.total_days} days
              </p>
            </Revealable>
          : null
        }
      </div>
    : null
}

const CountdownElement = ({number, caption}) =>
  <div className="countdown-element">
    <div className="number">{number}</div>
    <div className="caption">{caption}</div>
  </div>

export const ComputeCounter = countdown => {
  if (! countdown) {
    return null;
  }
  const now      = new Date();
  const cutoff   = new Date(`${countdown.date_today}T${countdown.cutoff_today}`);
  const tomorrow = new Date(`${countdown.date_tomorrow}T${countdown.cutoff_time}`);
  const nextcut  = new Date(`${countdown.next_workday}T${countdown.cutoff_time}`);
  const nextcut2 = new Date(`${countdown.next_next_workday}T${countdown.cutoff_time}`);
  const tdiff    = Math.floor((cutoff.getTime()  - now.getTime()) / 1000);
  const ndiff    = Math.floor((nextcut.getTime() - now.getTime()) / 1000);
  let counter = { now, cutoff, nextcut, tdiff, ndiff, ready: true };
  counter.order_today = countdown.today_is_workday && tdiff > 0;
  counter.order_nwd   = ndiff > 0;
  counter.tomorrow    = tomorrow.toLocaleDateString('en-GB', { weekday: 'long', day: 'numeric', month: 'long' });
  counter.nwd         = nextcut.toLocaleDateString('en-GB', { weekday: 'long', day: 'numeric', month: 'long' });
  counter.nnwd        = nextcut2.toLocaleDateString('en-GB', { weekday: 'long', day: 'numeric', month: 'long' });

  // are we looking at today or the next working day?
  let diff = counter.order_today ? tdiff : ndiff;
  if (diff <= 0) {
    // all deadlines are the in past (maybe user hasn't refreshed browser in days)
    return null;
  }

  // const secs  = counter.total_secs  = diff;
  const mins  = counter.total_mins  = Math.floor(diff  / 60);
  const hours = counter.total_hours = Math.floor(mins  / 60);
  const days  = counter.total_days  = Math.floor(hours / 24);
  counter.dispatch = counter.order_today
    ? 'today'
    : `on ${counter.nwd}`;
  counter.receive  = counter.order_today
    ? (countdown.tomorrow_is_workday
      ? 'tomorrow'
      : `on ${nextcut.toLocaleDateString('en-GB', { weekday: 'long' })}`)
    : `on ${nextcut2.toLocaleDateString('en-GB', { weekday: 'long' })}`;
  counter.seconds = diff  % 60;
  counter.minutes = mins  % 60;
  counter.hours   = hours % 24;
  counter.days    = days;
  counter.level   = counter.order_today
    ? ( counter.hours   >= 1  ? '' :
        counter.minutes >= 30 ? 'warning' :
        counter.minutes >= 10 ? 'danger'  : 'critical' )
    : '';

  return counter;
}


export default Sales.Consumer(Countdown)