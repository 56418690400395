import React from 'react'
import Sales from '../../context/Sales'
import ProductTypes from '../../config/ProductTypes'
import { Icon } from '@abw/badger-ui';

const ProductType = ({sales}) =>
  <>
    <h4 className="mar-t mar-b-none smallish">Product category</h4>
    <div className="categories options product_types row">
    { ProductTypes.map(
        category => {
          const selected = sales.category && sales.category.uri === category.uri;
          const select   = () => sales.setCategory(selected ? undefined : category);
          return <div className="option" key={category.uri}>
            <div className={`category item ${selected?'selected':''}`} onClick={select}>
              <div className="title flex space">
                {category.title}
                <span className="icon-span"><Icon name={category.icon} className="x3" fixedWidth/></span>
              </div>
            </div>
          </div>
        }
    )}
    </div>
  </>

export default Sales.Consumer(ProductType)