import React from 'react'
import Sales from '../../context/Sales'
import URLS  from '../../config/URLS'
import PriceAdd from './PriceAdd'
import { productPictureURL } from '../../utils/Products'
import { NewBanner, NewSticker } from '../../config/Site'
import { Link, Loading, Loader, IconStack } from '@abw/badger-ui'

const Card = ({sales, basket, product, setRef, size=240, showNew=true, showTitle=true, showHires=false}) => {
  if (! sales.ready || ! basket) {
    return <Loading overlayColor="black"/>
  }
  const selected = basket.has_product[product.uri];

  return <Link to={URLS.product(product.uri)} bare ref={setRef} as="div" className="relative inline-block">
    <div className={`product-card ${selected ? 'selected' : ''}`}>
      <div className="picture relative">
        <img src={productPictureURL(product, size)} alt={product.name} width={size} height={size}/>
        { product.new && showNew
          ? <>
              { NewSticker
                ? <div className="new-sticker shadow">
                    <IconStack
                      backIcon="disc" backColor="violet" backTransform=""
                      foreIcon="new_right" foreColor="white"
                    />
                  </div>
                : null
              }
              { NewBanner
                ? <div className="new-banner">New in Stock</div>
                : null
              }
            </>
          : null
        }
      </div>
      { showTitle
        ? <TitleInfo product={product}/>
        : false
      }
      { showHires
        ? <HireInfo product={product}/>
        : false
      }
      <PriceAdd product={product}/>
      { sales.saving === 'add-product-' + product.uri
        ? <Loader bgColor="transparent" overlayColor="blackish" message="Adding to basket..." textSize="smallish"/>
        : null
      }
    </div>
  </Link>
}

const TitleInfo = ({product}) =>
  <div className="info">
    <h4 className="manufacturer">{product.manufacturer_name}</h4>
    <h5 className="model">{product.model}</h5>
  </div>

const HireInfo = ({product}) =>
  <div className="hire-info">
    { product.n_hires
      ? <>
          Hired {
            product.n_hires === 1 ? 'once' :
            product.n_hires === 2 ? 'twice' :
            product.n_hires + ' times'
          }.{' '}
        </>
      : null
    }
    { product.last_hired
      ? <>
          Last hired {product.last_hired}
        </>
      : null
    }
  </div>

export default Sales.Consumer(Card)

