import React from 'react'
import Features from '../Features'

const FeaturesPanel = () =>
  <div className="features product-panel">
    <h3 className="title">Features</h3>
    <div className="panel-body scroll-y">
      <Features/>
    </div>
  </div>

export default FeaturesPanel