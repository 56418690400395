import preval from 'preval.macro'
export const Updated           = preval`module.exports = new Date().toLocaleString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' });`;
export const Year              = preval`module.exports = new Date().toLocaleString('en-GB', { year: 'numeric' });`;
export const SiteVersion       = process.env.REACT_APP_SITE_VERSION;
export const Version           = process.env.REACT_APP_VERSION;
export const APIServer         = process.env.REACT_APP_API_SERVER
export const MediaServer       = process.env.REACT_APP_MEDIA_SERVER
export const PublicServer      = process.env.PUBLIC_URL
export const GoogleAnalytics   = process.env.REACT_APP_ANALYTICS_ID; //'UA-5956539-1';
export const TrustpilotReviews = process.env.REACT_APP_TRUSTPILOT_REVIEWS;
export const APIServerURL      = uri => APIServer   + uri
export const MediaServerURL    = uri => MediaServer + uri
export const PublicURL         = uri => PublicServer + uri
export const PictureURL        = (width, uri) => MediaServerURL(`/images/products/${width}/${uri}`)
export const RecaptchaKey      = '6LfT7dMeAAAAAMvcxAhSWIorXNF4lsXN6Srb_rv8';
export const IdealPCKey        = 'ak_l2k8hb0uLXpg7gj7rTjZzaKR3hCIP';
export const GoogleMapsKey     = 'AIzaSyAoXiRNbBkz37DEWOXcBwbUeGfdgToS444';
export const OfficeLocation    = { lat: 51.5357, lng: -0.7358 };
export const Telephone1        = '0800 61 272 61';
export const Telephone2        = '01628 639941';
export const HelpEmail         = 'help@lensesforhire.co.uk';
export const ConsentCookie     = 'lfh_consent';
export const ConsentEssential  = 'essential';
export const ConsentAnalytics  = 'analytics';
export const ConsentSearch     = 'search';
export const CookieOptions     = { path: '/', 'max-age': 31536000, SameSite: 'None' };
export const GravatarBaseURL   = 'https://www.gravatar.com/avatar/';
export const GravatarSize      = 80;
export const GravatarDefault   = 'https://lensesforhire.co.uk/images/user/user-avatar.png';
export const NewMaxDays        = 200;
export const NewBanner         = false;
export const NewSticker        = true;
export const BasketCountdown   = false;
export const ShowRachelRiley   = false;
export const TestAnalytics     = false;
export const DebugAnalytics    = false;
export const PretendOrder      = false;
export const DebugIncomplete   = false;