import React from "react"
import Sales from "../../context/Sales"
import { PictureURL, PublicURL } from '../../config/Site'
import { Icon } from "@abw/badger-ui"
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Pictures = ({product}) => {
  const n = product.pictures?.length || 0;
  if (n > 1) {
    return <PicturesCarousel product={product}/>
  }
  else if (n === 1) {
    return <PictureSingle product={product} picture={product.pictures[0]}/>
  }
  else {
    return <NoPicture/>
  }
}

const PicturesCarousel = ({product, openModal}) =>
  <div className="pictures-carousel">
    <Carousel emulateTouch={true} onClickItem={openModal} showThumbs={false} showStatus={false}
      renderArrowPrev={(onClickHandler, hasPrev) => <PrevArrow onClick={onClickHandler} hasPrev={hasPrev}/>}
      renderArrowNext={(onClickHandler, hasNext) => <NextArrow onClick={onClickHandler} hasNext={hasNext}/>}
    >
      { product.pictures.map(
          (picture, k) =><div key={k} className="carousel-item">
            <Picture product={product} picture={picture}/>
          </div>
      )}
    </Carousel>
  </div>

const PrevArrow = ({onClick, hasPrev}) =>
  <div className={`prev nav ${hasPrev ? 'active' : ''}`} onClick={onClick}>
    <Icon name="angle_left" className="x3"/>
  </div>
const NextArrow = ({onClick, hasNext, ...props}) =>
  <div className={`next nav ${hasNext ? 'active' : ''}`} onClick={onClick}>
    <Icon name="angle_right" className="x3"/>
  </div>

const Picture = ({product, picture}) => {
  const p300 = PictureURL(300, picture.uri);
  const p480 = PictureURL(480, picture.uri);
  const p600 = PictureURL(600, picture.uri);
  return <img
      srcSet={`${p300} 300w, ${p480} 480w, ${p600} 600w`}
      src={p480}
      alt={picture.title||product.name}
      className="picture"
    />
}

// Safari fucks up the image size when using srcSet to we have to cripple
// the site
const PictureSingle = ({product, picture}) => {
  const p480 = PictureURL(480, picture.uri);
  return <img
      src={p480}
      alt={picture.title||product.name}
      className="picture"
    />
}

const NoPicture = () =>
  // eslint-disable-next-line jsx-a11y/img-redundant-alt
  <img
    src={PublicURL('/img/misc/no-picture.svg')}
    alt="No picture available"
    className="picture"
  />

export default Sales.Consumer(Pictures)
