import React from 'react'
import { timestamp } from '@abw/badger-timestamp'
import { isDefined } from './Misc.jsx'

export const SomeTimes = ({from, to, inside, outside, children}) => {
  const now = timestamp();
  // console.log('time is now', now.stamp());

  if (isDefined(from) && now.before(from)) {
    // console.log('%s is before %s', now.stamp(), from);
    return outside || null;
  }
  if (isDefined(to) && now.after(to)) {
    // console.log('%s is after %s', now.stamp(), to);
    return outside || null;
  }
  // console.log('%s is between %s and %s', now.stamp(), from, to);
  return <>
    {inside || children}
  </>
}

export default SomeTimes
