import { isDefined } from './Misc'

export function getQueryParams(u) {
    const url = u || window.document.URL.toString();
    let params = { };

    if (url.indexOf('?') > 0) {
        const args = url.split('?')[1].split('&');
        args.forEach(
            arg => {
                const tokens = arg.split("=");
                const name   = tokens[0];
                const value  = (tokens.length > 1 && tokens[1].length)
                    ? decodeURIComponent(tokens[1])
                    : '';
                if (params[name]) {
                    params[name] = params[name] + '|' + value;
                }
                else {
                    params[name] = value;
                }
            }
        );
    }
    return params;
}

export function encodeQueryParams(params) {
    return Object.keys(params)
      .filter( key => isDefined(params[key]) && params[key].toString().length )
      .map( key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) )
      .join('&')
}

