import React from 'react'
import { Icon } from '@abw/badger-ui'

const Key = ({className=''}) =>
  <div className="calendar-keys mar-t-2">
    <KeyElement caption="Plenty available" className="day lots" icon="check"/>
    <KeyElement caption="Limited availability (1 or 2 in stock)" className="day some" icon="limited"/>
    <KeyElement caption="None available" className="day none" icon="times"/>
  </div>

const KeyElement = ({caption, icon, className}) =>
  <table className="calendar calendar-key">
    <tbody>
      <tr className="availability">
        <td className={className}><Icon name={icon}/></td>
        <td className="caption">{caption}</td>
      </tr>
    </tbody>
  </table>


export default Key
