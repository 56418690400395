import React from 'react'
import Sales from '../../context/Sales'
import URLS  from '../../config/URLS'
import Price from './Price'
import { Button } from '@abw/badger-ui'
import { preventDefault } from '../../utils'
import { useNavigate } from 'react-router-dom'

const PriceAdd = ({sales, basket, product, selectDays}) => {
  const navigate = useNavigate();
  const selected = basket.has_product[product.uri];
  return <div className="price-add">
    <Price product={product} selectDays={selectDays}/>
    { selected
      ? <Button
          iconLeft="check"
          text="Added"
          className="solid button mar-b-none"
          onClick={preventDefault(() => navigate(URLS.basket.home))}
        />
      : <Button
          iconLeft="basket" text="Add" className="solid mar-b-none"
          onClick={preventDefault(() => sales.basketAddProduct(product))}
        />
    }
  </div>
}

export default Sales.Consumer(PriceAdd)