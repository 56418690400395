import React, { useEffect } from 'react'
import Sales from '../context/Sales'
import URLS from '../config/URLS'
import Info from '../components/Product/Info'
import { Helmet } from 'react-helmet-async'
import { useAnalytics } from 'use-analytics'
import { getConsentAnalytics } from '../utils/Cookies'
import { Link, Loading } from '@abw/badger-ui'
import { Warning } from '../ui'

const ProductLoader = ({sales, product}) =>
  product.loading
    ? <Loading overlayColor="black"/>
    : <Product sales={sales} product={product}/>

const Product = ({sales, product}) => {
  const { track } = useAnalytics();

  // generate a product view tracking event...
  useEffect(
    () => {
      // ...only if we have a cookie indicating analytics consent
      const consent = getConsentAnalytics();
      if (consent) {
        track(
          'view_item',
          {
            category: product.camera_system_name || product.manufacturer_name,
            label:    product.name,
          }
        );
      }
    },
    [track, product.camera_system_name, product.manufacturer_name, product.name]
  );

  return <>
    <Helmet>
      <title>LFH - {product.name}</title>
    </Helmet>
    <div className="container tablet">
      <div className="flex space center wrap mar-b">
        <h1 className="mar-v-none flex product-title">{product.name}</h1>
        <Link to={URLS.search} iconLeft="angle-left" className="blue back" text="Back"/>
      </div>
      { ! product.active
        ? <Warning
            className="mar-t-none"
            title="THIS PRODUCT IS NOT AVAILABLE TO HIRE"
            text="This page is provided for information purposes only."
          />
        : null
      }
      <Info/>
      <Link
        to={URLS.search}
        iconLeft="angle-left"
        className="subdued blue button mar-t-2"
        text="Back to search"
      />
    </div>
  </>
}

export default Sales.Consumer(ProductLoader)
