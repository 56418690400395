import React from 'react'
import URLS from '../config/URLS'
import { Version, Year, Updated, SiteVersion, HelpEmail, Telephone1, Telephone2 } from '../config/Site'
import { Link, Icon, IconStack } from '@abw/badger-ui';
import Celebrating from './Celebrating.jsx';
import SomeTimes from '../utils/SomeTimes.jsx';

const Footer = () =>
  <footer className="site">
    <div className="container">
      <div className="links-and-icon">
        <div className="links">
          <ul className="none">
            <li><a href={`mailto:${HelpEmail}`}>{HelpEmail}</a></li>
            <li><a href={`tel:${Telephone1}`}>{Telephone1}</a></li>
            <li><a href={`tel:${Telephone2}`}>{Telephone2}</a></li>
          </ul>
          <ul>
            <li><Link to={URLS.home} text="Home"/></li>
            <li><Link to={URLS.about.home} text="About us"/></li>
            <li><Link to={URLS.about.contact} text="Contact us"/></li>
          </ul>
          <ul>
            <li><Link to={URLS.about.guide} text="Guide to hiring"/></li>
            <li><Link to={URLS.about.faq} text="FAQs"/></li>
            <li><Link to={URLS.about.terms} text="Terms and conditions"/></li>
          </ul>
        </div>
        <div className="uk-no-1">
          <SomeTimes
            from="2023-02-01 00:00:00"
            to="2023-12-31 23:59:59"
            inside={
              <Celebrating size="65%"/>
            }
            outside={
              <IconStack
                // className="x10"
                backIcon="diamond" backColor="yellow" backTransform=""
                foreIcon="uks_no_1" foreColor="black"
              />
            }
          />
        </div>
      </div>
      <div className="info row stack-tablet">
        <div className="copyright split-3-5">
          <Icon name="copyright" className="green x2 mar-r"/> Copyright {Year} Lenses for Hire
        </div>
        <div className="version text-right split-2-5">
          Version {SiteVersion}/{Version} - {Updated}
        </div>
      </div>
    </div>
  </footer>


export default Footer