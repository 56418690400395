import React, { useEffect } from 'react'
import Sales from '../context/Sales'
import { useParams } from 'react-router-dom';
import { Loading } from '@abw/badger-ui';

export const SelectProduct = Sales.Consumer(
  ({sales, product, Component}) => {
    if (! sales.ready) {
      return <Loading overlayColor="black"/>
    }
    const {uri} = useParams();
    const selector = sales.selectProduct;
    useEffect(
      () => selector(uri),
      [selector, uri]
    );
    return product && product.uri === uri
      ? <Component/>
      : <Loading overlayColor="black"/>
  }
);
