import { Container, Link } from '@abw/badger-ui'
import React from 'react'
import { PublicURL } from '../config/Site'
import URLS from '../config/URLS'

export const NotFound = () =>
  <Container className="text-center">
    <h1>404 Not Found</h1>
    <p>
      I'm sorry Dave, I'm afraid I can't do that.
    </p>
    <img
      src={PublicURL('/img/misc/HAL9000.svg')} width="300" alt="HAL9000"
      style={{filter: "drop-shadow(0 0 20px #000)"}}
    />
    <h2 className="mar-t-4">Are you looking for one of these pages?</h2>
    <Link to={URLS.home}       className="largish mar-h-2" text="Home Page"/>
    <Link to={URLS.search}     className="largish mar-h-2" text="Product Search"/>
    <Link to={URLS.about.home} className="largish mar-h-2" text="About Us"/>
  </Container>

export default NotFound