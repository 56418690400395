import React from 'react'
import MiniCountdown from './MiniCountdown'
import { Icon } from '@abw/badger-ui'
import { Telephone2 } from '../../config/Site'

const NeedAdvice = () =>
  <div className="container mar-b-6">
    <hr className="green mar-b-4" />
    <div className="split-421">
      <div>
        <Icon name="richie" className="orange x4 mar-b-2"/>
        <p>
          Need advice?<br/>
          Call us on {Telephone2}
        </p>
      </div>
      <div>
        <Icon name="lens_wide" className="yellow x4 mar-b-2"/>
        <p>
          Need a specific lens?<br/>
          We have a huge range.
        </p>
      </div>
      <div>
        <Icon name="camera" className="blue x4 mar-b-2" transform="grow-2 down-2 right-2"/>
        <p>
          Need a camera?<br/>
          You can hire these too.
        </p>
      </div>
      <div>
        <Icon name="courier2" className="red x4 mar-b-2" transform="grow-4 right-2 down-2"/>
        <MiniCountdown/>
      </div>
    </div>
  </div>


export default NeedAdvice