import React from 'react'
import { Icon, IconStack } from '@abw/badger-ui'

export const Panel = ({
  title, icon, children, status,
  id="", className="", bodyClass="",
  closed=false,
  closeable=closed,
  openMsg="click to open panel",
  closeMsg="click to close panel",
  color='',
  Notes,
  Footer,
  ClosedFooter,
  scrollRef
}) => {
  const [isClosed, setClosed] = React.useState(closed)

  React.useEffect(() => {
    setClosed(closed);
  }, [closed])

  if (scrollRef && scrollRef.current) {
    scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return <div
    className={`panel ${closeable ? 'closeable' : ''} ${isClosed ? 'closed' : 'open'} ${className} ${color}`}
    id={id} ref={scrollRef}
  >
    { title
      ? <h3 className="title" onClick={closeable ? () => setClosed(! isClosed) : null}>
          <span className="flex-grow">{ icon ? <Icon name={icon}/> : null }{title}</span>
          { closeable
            ? <div className="flex-shrink smaller mar-r">{isClosed ? openMsg : closeMsg}</div>
            : null
          }
          { status && <span className="status"><StatusIcon status={status}/></span>}
        </h3>
      : null
    }
    { isClosed
      ? null
      : <>
          { Notes
            ? <div className="notes pad"><Notes/></div>
            : null
          }
          <div className={`panel-body ${bodyClass}`}>
            {children}
          </div>
        </>
    }
    { Footer
      ? Footer
      : (ClosedFooter && isClosed)
        ? ClosedFooter
        : null
    }
  </div>
}

export const RoundIcon = ({
  name="check", color,
  className="",
  bgIcon="circle",
  bgColor="black",
  bgTransform="grow-4"
}) =>
  <IconStack
    className={className}
    backIcon={bgIcon} backColor={bgColor} backTransform={bgTransform}
    foreIcon={name} foreColor={color}
  />

export const StatusIcon = ({status}) =>
  status === 'complete'
    ? <RoundIcon name="check-circle" color="green"/> :
  status === 'warning'
    ? <RoundIcon name="circle-exclamation" color="orange" className="status"/> :
  null;


export default Panel
