export const contactTypes = {
  telephone: {
    type:         'telephone',
    title:        'Telephone',
    icon:         'phone',
    placeholder:  'Add telephone number',
    prompt:       'Add a contact number',
    label:        <b>Telephone Number</b>,
    button:       'Add Number',
    required:     'Please enter at least one current phone number and optionally, the details of your professional or personal websites and social media accounts.',
    location:     true,
    delete:       'Delete Number'
  },
  mobile: {
    icon:         'mobile',
  },
  work: {
    icon:         'phone',
  },
  home: {
    icon:         'phone',
  },
  website: {
    type:         'website',
    title:        'Website',
    placeholder:  'https://your-website.co.uk',
    website:      true,
  },
  facebook: {
    type:         'facebook',
    title:        'Facebook',
    placeholder:  'https://www.facebook.com/your-facebook-id/',
    website:      true,
  },
  twitter: {
    type:         'twitter',
    title:        'Twitter',
    placeholder:  'https://twitter.com/your-twitter-id',
    website:      true,
  },
  instagram: {
    type:         'instagram',
    title:        'Instagram',
    placeholder:  'https://www.instagram.com/your-instagram-id/',
    website:      true,
  },
  linkedin: {
    type:         'linkedin',
    title:        'LinkedIn',
    placeholder:  'https://www.linkedin.com/in/your-linkedin-profile/',
    website:      true,
  }
};
export const combinedSocialType = {
  type:         'social',
  icon:         'hashtag',
  title:        'Social Media',
  placeholder:  'https://website-address.co.uk',
  prompt:       'Add a social media link',
  label:        'Website Address',
  button:       'Add Website',
  delete:       'Delete Website',
  website:      true,
};


export default contactTypes