import React from 'react'
import Sales from '../../context/Sales'
import LensMounts from '../../config/LensMounts'

const LensMount = ({sales}) => {
  const bodyType = sales.bodyType;

  if (!bodyType) {
    return null
  }
  const lensMounts = LensMounts[bodyType.uri];
  if (!lensMounts) {
    return null
  }

  return <>
    <div className="lens-mounts mar-t">
    { lensMounts.map(
        mount => {
          const selected = sales.lensMount && sales.lensMount.uri === mount.uri;
          const select   = () => sales.setLensMount(selected ? undefined : mount);
          const Svg      = mount.svg;
          return <div className="option" key={mount.uri}>
            <div className={`lens-mount item ${selected?'selected':''}`} onClick={select}>
              <Svg/>
            </div>
          </div>
        }
    )}
    </div>
  </>
}

export default Sales.Consumer(LensMount)