import React from 'react'
import { Alert } from '@abw/badger-ui'

export const Info = (props) =>
  <Alert type='info' icon='info-ring' {...props } />
export const Success = (props) =>
  <Alert type='success' icon='success-ring' {...props } />
export const Warning = (props) =>
  <Alert type='warning' icon='warning-ring' {...props } />
export const Question = (props) =>
  <Alert type='question' icon='question-ring' {...props } />
export const Error = (props) =>
  <Alert type='error' icon='error-ring' {...props } />
