import React from 'react'
import Auth from '../context/Auth'
// import { useAnalytics } from 'use-analytics'
import { markover } from '../utils'

const Test = ({user}) => {
  // const { track, page, identify } = useAnalytics()
  return (
    <div className="container">
      {/*
      <div className="XXbg-white pad-2">
        <h1>Google Analytics Test</h1>
        <Button color="blue" solid onClick={() => track('trackThing')} text="Track Event"/>
        <Button color="green" solid onClick={() => page()} text="Trigger page view"/>
        { user && user.is_logged_in
          ? <Button color="violet" solid
              onClick={() => identify('userID', { name: user.name, email: user.email })}
              text="Identify User"/>
          : <div>Not logged in</div>
        }
      </div>
      */}
      <h1>Markover Test</h1>
      <table className="wide table">
        <thead>
          <tr>
            <th className="split-3">Input</th>
            <th className="split-3">HTML</th>
            <th className="split-3">Output</th>
          </tr>
        </thead>
        <tbody>
          <MarkupTest text="This is [bold:bold text] and [b:so is this]."/>
          <MarkupTest text="This is [italic:italic text] and [i:so is this]."/>
          <MarkupTest text="This is [under:underline text] and [u:so is this]."/>
          <MarkupTest text="This is [b:[i:bold and italic text]] and [b+i:so is this]."/>
          <MarkupTest text="This is [b:[u:bold and underline text]] and [b+u:so is this]."/>
          <MarkupTest text="This is [u:[i:underline and italic text]] and [u+i:so is this]."/>
          <MarkupTest text="This is [strike:strikeout text]."/>
          <MarkupTest text="This is [code:code]."/>
          <MarkupTest text="This is [quote:quoted text]."/>
          <MarkupTest text="This is [red:red text] and this is [b+red:bold red text]."/>
          <MarkupTest text="This is [green:green text] and this is [b+green:bold green text]."/>
          <MarkupTest text="This is [blue:blue text] and this is [b+blue:bold blue text]."/>
          <MarkupTest text="Link: [link:/about/contact|Contact Us]."/>
          <MarkupTest text="Link: [link:https://google.com|External Link]."/>
          <MarkupTest text="Product Link: [product:test-product|Test Product]."/>
          <MarkupTest text="Some [red:red text with [b:bold], [i:italic] and [u+i:underline italic] parts]"/>
        </tbody>
      </table>
    </div>
  )
}

const MarkupTest = ({text}) =>
  <tr valign="top">
    <td className="mono">{text}</td>
    <td className="mono">{markover(text)}</td>
    <td dangerouslySetInnerHTML={{__html:markover(text)}}/>
  </tr>

export default Auth.Consumer(Test)