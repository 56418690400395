import React, { useState } from 'react'
import URLS from '../config/URLS'
import { Button, Checkbox, Container, Link } from '@abw/badger-ui'
import { getConsentEssential, setConsent } from '../utils/Cookies'
import { ConsentEssential, ConsentAnalytics, ConsentSearch } from '../config/Site'

const Cookies = () => {
  const [saved, setSaved] = useState(getConsentEssential())
  return saved
    ? null // user has already consented to essential cookies
    : <CookiesPanel setSaved={setSaved}/>
}

const CookiesPanel = ({setSaved}) => {
  const [analytics, setAnalytics] = useState(true);
  const [search,    setSearch   ] = useState(true);
  const saveOptions = (e, a, s) => {
    setConsent([
      e ? ConsentEssential : null,
      a ? ConsentAnalytics : null,
      s ? ConsentSearch    : null,
    ]);
    setSaved(true);
  }
  const save      = () => saveOptions(true, analytics, search);
  const acceptAll = () => saveOptions(true, true, true);
  const rejectAll = () => saveOptions(true, false, false);

  return (
    <div className="cookies-panel">
      <Container className="bg-dark pad-2">
        <h2>Cookies and Local Storage</h2>
        <div className="row stack-tablet">
          <div className="split-2 gut-r mar-b-2">
            We use one cookie that is strictly essential for
            the operation of this web site. If you give your consent then
            we will use additional cookies for Google Analytics (e.g.
            so we can see which pages on our site are viewed more often than others)
            and local storage to save your search preferences. For
            more information please see our <Link to={URLS.about.privacy} text="Privacy Policy"/>.
          </div>
          <div className="split-2 gut-l">
            <Checkbox className="borderless" text="Strictly Essential" value={true}      disabled/>
            <Checkbox className="borderless" text="Google Analytics"   value={analytics} onChange={setAnalytics}/>
            <Checkbox className="borderless" text="Search Parameters"  value={search}    onChange={setSearch}/>
            <div className="flex space flex-wrap">
              <Button color="red"  text="Reject All" iconLeft="times" onClick={rejectAll}/>
              { ! analytics || ! search
                  ? <Button color="blue" solid text="Save" iconRight="floppy-disk" onClick={save}/>
                  : null
              }
              <Button color="green" solid text="Accept All" iconLeft="check" onClick={acceptAll}/>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Cookies