import axios from 'axios'
import { APIServer } from '../config/Site'
import { toast } from 'react-toastify'
import URLS from '../config/URLS';
import { Icon } from '@abw/badger-ui';

export let APIService = axios.create({
    baseURL: APIServer,
    responseType: "json",
    withCredentials: true
});

export const responseStatusIsOK = response => {
    return response.status >= 200
        && response.status <= 299;
}

export const responseData = response => ({
    ok:       responseStatusIsOK(response) && ! response.data.error,
    response: response,
    status:   response.status,
    data:     response.data,
    message:  response.data.message || response.data.success || response.data.error,
    result:   response.data.status,
});

export const errorData = error => {
    return error.response
        ? responseData(error.response)
        : {
            ok:      false,
            status:  999,
            message: error,
            result:  "error",
        };
}

// default is to display toast messages for both success and error
export const ToastAll = {
    successMessage: true,
    errorMessage:   true,
}

// - this is the quieter option
export const ToastErrors = {
    successMessage: false,
    errorMessage:   true,
};

// - this is a special option to display a link to the basket
export const ToastBasket = {
    successMessage: false,
    errorMessage:   true,
    basketLink:     true,
};


export const responseHandler = (options = ToastAll) => json => {
    if (json.ok) {
        // console.log("OK response: ", json);
        if (json.message) {
            if (options.successMessage) {
                toast.success(json.message);
            }
            else if (options.basketLink) {
                toast.success(
                  <>
                    <div>{json.message}</div>
                    <div className="text-right">
                      <a href={URLS.basket.home} className="small green button mar-t mar-b-none">
                        <span className="caption mar-r">View basket</span>
                        <Icon name="arrow-right" color="green"/>
                      </a>
                    </div>
                  </>
                )
            }
        }
    }
    else {
        // console.log("ERROR response: ", json);
        if (json.message && options.errorMessage) {
            toast.error(json.message);
        }
        else {
            console.log("Response error: ", json);
        }
        throw json;
    }
    return json;
}

export const responder = (request, opts) => {
    return request
        .then(responseData)
        .catch(errorData)
        .then(responseHandler(opts));
}

export const GET = (url, defaultOpts) => {
    return (params, opts=defaultOpts) => {
        return responder(
            APIService.get(url, {params}), opts
        );
    }
}

export const POST = (url, defaultOpts) => {
    return (params, opts=defaultOpts) => {
        return responder(
            APIService.post(url, PostParams(params)), opts
        );
    }
}

export const PostParams = (params) => {
    let body = new FormData();
    if (params) {
        Object.keys(params).forEach(
            key => body.append(key, params[key])
        );
    }
    return body;
}
