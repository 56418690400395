import React, { useEffect, useState } from 'react'
import { PublicURL } from '../../config/Site'
import { BodyTypes } from '../../config/BodyTypes'
import { Icon, Link } from '@abw/badger-ui'
import Sales from '../../context/Sales'

export const HeroTBYB = ({sales}) => {
  const [typeCount, setTypeCount] = useState(0);
  const [bgImg, setBgImg] = useState('/img/background/lens2-1500b.jpg');

  useEffect(() => {
    const interval = setInterval(
      () => {
        const n = (typeCount + 1) % BodyTypes.length;
        setTypeCount(n);
        setBgImg(`/img/background/body_type/${BodyTypes[n].uri}.jpg`);
      },
      5000
    );
    return () => clearInterval(interval);
  }, [typeCount]);


  return (
    <div className="hero text-center tbyb">
      <div
        className="bgimg" key={typeCount}
        style={{  background: `url(${PublicURL(bgImg)}) center center no-repeat` }}
      />
      <div className="container">
        <h1 className="ultra">
          lens envy no more
        </h1>
        <div className="lhme">
          Hire the kit you've always wanted
          <div className="get-started">
            <Link to="search" className="blue button">
              Get started
              <Icon name="angle-right"/>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sales.Consumer(HeroTBYB)