import { Icon, Link } from '@abw/badger-ui';
import React from 'react'
import URLS from '../config/URLS';
import { Panel } from '../ui';
import { getQueryParams } from '../utils';

const OrderPlaced = () => {
  const [orderId, setOrderId] = React.useState('')
  React.useEffect(
    () => {
      const params = getQueryParams();
      console.log('query params: ', params);
      setOrderId(params.orderreference);
    },
    []
  );

  return (
    <div className="tablet container">
      <Panel bodyClass="pad-4">
      <h1 className="mar-t-none"><Icon name="success-ring" color="green"/> Your order has been placed</h1>
      <p className="intro">
        Thank you for your order.  Your order number is: <span className="orange">{orderId}</span>
      </p>
      <p>
        You will shortly receive an automated response to acknowledge that the
        order has been received.  We will contact you again via email to confirm
        your order and keep you informed about its progress.
      </p>
      <div className="text-right">
        <Link
          to={URLS.user.order(orderId)}
          className="blue subdued button mar-b-none"
          text="View order details"
          iconRight="angle-right"
        />
      </div>
      </Panel>
    </div>
  )
}

export default OrderPlaced