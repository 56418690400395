import React, { useEffect, useState } from 'react'
import Sales from '../../context/Sales'
import { ComputeCounter } from '../Basket/Countdown';

const Countdown = ({basket}) => {
  const countdown = basket?.countdown;
  const [counter, setCounter] = useState(ComputeCounter(countdown));

  useEffect(() => {
    if (countdown) {
      const timerId = setInterval(
        () => setCounter(ComputeCounter(countdown)),
        1000
      );
      return () => clearInterval(timerId);
    }
  }, [countdown]);

  const mins  = counter?.minutes || 0;
  const secs  = counter?.seconds || 0;

  return countdown && counter?.ready
    ? <>
        <p>Want it {counter.receive}? You have{' '}
          { counter.total_hours > 24
            ? <>{counter.total_hours}+ hours</>
            : <>
                { counter.total_hours
                  ? <>{counter.total_hours}<span className="countdown-separator">:</span></>
                  : null
                }
                {mins.toString().padStart(2, '0')}<span className="countdown-separator">:</span>
                {secs.toString().padStart(2, '0')}
              </>
          }
          {' '}to place your order
        </p>
      </>
    : null
}

export default Sales.Consumer(Countdown)