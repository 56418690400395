import { splitToHash } from './Misc'
import { Months, Days } from '../config/Calendar'
import { NewMaxDays } from '../config/Site';

// Richie suggested grouping Lighting with Accessories
const FixTypes = {
  lighting: 'accessory'
};

export function prepareSalesData(data) {
  const prices    = preparePrices(data.prices);
  const products  = prepareProducts(data.products, prices);
  const basket    = prepareBasket(data.basket);
  const countries = prepareCountries(data.countries || [ ]);
  const tpcount   = parseInt(data.trustpilot_review_count);
  let productIndex = { };
  let latestProducts = { };

  products.forEach(
    product => {
      productIndex[product.uri] = product;
      const bt = product.camera_system_uri;
      if (bt?.length) {
        let btl = latestProducts[bt] || (latestProducts[bt] = [ ]);
        btl.push(product);
      }
    }
  )
  Object.entries(latestProducts).forEach(
    ([type, list]) => {
      const sorted = list.sort(numberSorter('days_old')).slice(0, 10);
      sorted.forEach(
        product => {
          product.intro = product.description?.replace(/<.*?>/g, '').split(/[.!?]/)[0];
        }
      )
      latestProducts[type] = sorted;
    }
  )
  return { products, productIndex, latestProducts, prices, basket, countries, tpcount };
}

export function prepareProducts(products, prices) {
  products.forEach(
    product => prepareProduct(product, prices)
  );
  return products;
}

// basic data returned by sales product index
export const prepareProduct = (product, prices) => {
  product.lens_type  = product.lens_types ? splitToHash(product.lens_types, /\|/) : { };
  const mname  = product.manufacturer_name;
  const pname  = product.name;

  // type tweaks, e.g. to merge lighting into accessories
  product.type = FixTypes[product.type] || product.type;

  // remove the manufactuer name from the start of the product name to
  // get the model, e.g. Canon EOS 5D Mark IV => EOS 5d Mark IV
  product.model = mname && pname && pname.startsWith(mname + ' ')
    ? pname.slice(mname.length + 1)
    : pname;

  // copy the name into search text as lower case
  product.search = [
    product.manufacturer_name,
    product.camera_system_name,
    product.name,
    product.lens_types
  ].join(' ').toLowerCase();

  // fill out blanks for other data that we'll load later
  product.features = { };
  product.compatible = [ ];
  product.related = [ ];

  product.active = product.status === 'active';
  product.new = product.days_old < NewMaxDays;

  if (prices) {
    const range = prices[product.price_range] || { };
    product.price28 = parseFloat(range[28] || 0);
  }

  return product;
}

export const prepareProductInfo = (data, state) => {
  // We've got the "new" product data from the server in data.product and the "old"
  // product data from the sales product index in state.product.  We must make
  // sure that the product data we've received in the request matches the product
  // selected in the current state to avoid race conditions where the user selects
  // a number of different products in quick succession and we received a response
  // for a product that's no longer selected;
  const newProduct = data.product || { };
  const oldProduct = state.product;

  if (! oldProduct) {
    // There isn't a product selected - perhaps the user selected it and then
    // unselected it before the response was received?
    console.log("no product is selected, returning undefined");
    return undefined;
  }
  else if (newProduct.uri !== oldProduct.uri) {
    // if the ids don't match then assume the currently selected product is most valid
    console.log("id mismatch - returning currently selected product");
    return oldProduct;
  }

  const product = {
    ...oldProduct,
    ...newProduct,
    loading: false
  };

  product.availability = prepareAvailability(product.availability);

  return product;
}

export function searchProducts(search, products) {
    var words = search.toLowerCase().split(' ');
    return Object.values(products).filter(
        product => {
          return words.every(
            word => product.search.indexOf(word) >= 0
        )}
    );
}

export function prepareCountries(countries) {
  return countries.map(
    item => ({ ...item, text: item.name })
  );
}

export function preparePrices(prices) {
  return prices;
}

export function prepareBasket(basket) {
  basket.has_product = { };
  let n = 0;
  basket.items?.forEach(
    item => {
      basket.has_product[item.uri] = (basket.has_product[item.uri] || 0) + 1;
      item.n = n++;
    }
  );
  [ 'item_price_ex', 'item_price_vat', 'item_price_inc',
    'hire_ex', 'hire_vat', 'hire_inc',
    'deposit_ex', 'deposit_vat', 'deposit_inc',
    'discount_ex', 'discount_vat', 'discount_inc',
    'insurance_ex', 'insurance_vat', 'insurance_inc', 'insurance_value',
    'free_days_discount_ex', 'free_days_discount_vat', 'free_days_discount_inc',
    'total_ex', 'total_vat', 'total_inc'
  ].forEach(
    item => basket[item] = basket[item] ? parseFloat(basket[item]) : 0
  )
  return basket;
}

export function prepareAvailability(availability) {
  return availability;
}

export function OLDprepareAvailability(availability) {
  let calendar = {
    year: { },
    years: [ ]
  };
  availability.forEach(
    a => {
      const [year, month, day] = a.day.split('-');
      a.date = a.day;
      a.day  = day;

      // fetch year from calendar or create new year entry
      let calyear = calendar.year[year];
      if (! calyear) {
        calyear = { year, month: { }, months: [ ] };
        calendar.year[year] = calyear;
        calendar.years.push(calyear);
      }

      // fetch month from calendar year or create new month entry
      let calmon = calyear.month[month];
      if (! calmon) {
        calmon = { month, days: [ ], ...Months[month] };
        calyear.month[month] = calmon;
        calyear.months.push(calmon);

        // we want to display weeks starting on Monday so if the first date we
        // have in a month ISN'T a monday then we add in dummy entries for the days
        // leading up to the first day.  Hmm....  No, that's not going to work.
        // We might have the first day of the month as a wednesday, for example,
        // and we would need to add the last 2 days of the previous month.  That's
        // not too hard, but what should we show for availability?  If those days
        // are before today then they're "impossible to hire" days, but if the
        // use has skipped onto next month then we should show them.  Perhaps
        // better to make the server always return availability starting on a monday.
      }

      // work out day of week
      const date = new Date(a.date);
      const dom = date.getDay();
      Object.assign(a, Days[dom]);

      // add availability to calendar month
      calmon.days.push(a);
    }
  );
  return calendar;
}

const sorter = (field, primer) => {
  const key = record =>
    primer
      ?  primer(record[field])
      :  record[field];

  return (a,b) => {
    const A = key(a);
    const B = key(b);
    return (A < B) ? -1
      :    (A > B) ? +1
      :    0
  }
}

//const numberSorter = field => (a, b) =>
//  parseFloat(a[field]) - parseFloat(b[item]);
const numberSorter = field => (a, b) =>
  a[field] - b[field];

const minmaxSorter = field => (a, b) => {
  const amin = parseFloat(a['min_' + field]) || 99999;  // records without a min are sorted last
  const bmin = parseFloat(b['min_' + field]) || 99999;
  const amax = parseFloat(a['max_' + field]) ||     0;  // records without a max are sorted first
  const bmax = parseFloat(b['max_' + field]) ||     0;
  return (amin - bmin) || (amax - bmax);
}

export const sortMethods = {
  // added:          sorter('added'),
  name:           sorter('name'),
  price:          numberSorter('price28'),
  focal_length:   minmaxSorter('focal_length'),
  aperture:       minmaxSorter('fstop'),
  // special cases for user hired products
  n_hires:        numberSorter('n_hires'),
  last_hired:     sorter('last_hired'),
};

