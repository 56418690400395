import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import { DebugAnalytics, GoogleAnalytics, TestAnalytics } from '../config/Site'

const testAnalyticsPlugin = {
  name: "Test Analytics Plugin",
  page: ({ payload }) => {
    console.log("Test Analytics: page => ", payload);
  },
  track: ({ payload }) => {
    console.log("Test Analytics: track => ", payload);
  },
  identify: ({ payload }) => {
    console.log("Test Analytics: identify => ", payload);
  }
};

const analytics = Analytics({
  app: 'LFH-Development',
  plugins: [
    TestAnalytics
      ? testAnalyticsPlugin
      : googleAnalytics({
          trackingId: GoogleAnalytics,
          debug: DebugAnalytics
        }),
  ]
})

export default analytics