import React from 'react'

const Head = ({calendar}) => {
  const months = calendar.months || [ ];
  return (
    <thead>
      <tr className="month-names">
        { months.map(
            month => {
              const n_days = month.days.length;
              return <th key={month.name} className="caption month" colSpan={n_days}>
              { n_days < 2
                ? month.month
                : n_days < 5
                  ? month.name.substr(0, 3)
                  : month.name
              }{' '}
              { n_days >= 7 && month.year }
              </th>
            }
        )}
      </tr>
      <tr className="names">
        { months.map(
            month => month.days.map(
              day => <th
                key={`${month.year}-${month.month}-${day.day}-name`}
                className={`day name ${day.flags}`}
              >{day.name.substr(0, 2)}</th>
            )
        )}
      </tr>
      <tr className="names">
        { months.map(
            month => month.days.map(
              day => <th
                key={`${month.year}-${month.month}-${day.day}-number`}
                className={`day name ${day.flags}`}
              >{day.day}</th>
            )
        )}
      </tr>
    </thead>
  )
}

export default Head

