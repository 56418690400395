import React from 'react'
import Sales from '../../context/Sales'
import { range } from '@abw/badger-ui'

const SelectDays = ({sales, basket}) =>
  <select
    value={sales.hireDays()}
    onChange={ e => sales.setHireDays(e.target.value) }
  >
    <option value="0" hidden disabled>Select Duration</option>
    { range(3, 29).map(
        days => <option key={days} value={days}>for {days} days</option>
    )}
  </select>

export default Sales.Consumer(SelectDays)