import React from 'react'
import { Helmet } from 'react-helmet-async'
import Browse from '../components/Search/Browse'

const SearchPage = () =>
  <>
    <Helmet>
      <title>LFH - Product Search</title>
    </Helmet>
    <div id="search" className="container">
      <Browse/>
    </div>
  </>

export default SearchPage