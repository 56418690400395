import { useRef, useState, useEffect } from 'react'

export const useObserver = (options) => {
  const containerRef = useRef(null);
  const [isVisible, setVisible] = useState(false);

  const callback = (entries) => {
    setVisible(entries[0].isIntersecting);
  }
  useEffect(
    () => {
        const observer = new IntersectionObserver(callback, options);
        const current  = containerRef.current;
        if (current) {
          observer.observe(current);
        }
        return () => {
          if (current) {
            observer.unobserve(current);
          }
        }
    },
    [containerRef, options]
  );
  return [containerRef, isVisible];
}

export default useObserver;