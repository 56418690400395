export const BASE  = "";
export const IMGS = `${BASE}/img`;
export const URLS = {
    home:           `${BASE}/`,
    search:         `${BASE}/search`,
    xmas:           `${BASE}/christmas`,
    feb2023:        `${BASE}/feb2023`,
    placed:  id  => `${BASE}/order_placed?orderreference=${id}`,
    product: uri => `${BASE}/product/${uri}`,
    basket: {
        home:         `${BASE}/basket/`,
        hire:         `${BASE}/basket/hire`,
        availability: `${BASE}/basket/availability`,
        identify:     `${BASE}/basket/identify`,
        checkout:     `${BASE}/basket/checkout`,
        confirmation: `${BASE}/basket/confirmation`,
        place:        `${BASE}/basket/place_order`,
    },
    about: {
        home:        `${BASE}/about/`,
        faq:         `${BASE}/about/faq`,
        guide:       `${BASE}/about/guide`,
        terms:       `${BASE}/about/terms-and-conditions`,
        privacy:     `${BASE}/about/privacy-policy`,
        legal:       `${BASE}/about/legal`,
        contact:     `${BASE}/about/contact`,
        collect:     `${BASE}/about/collect`,
        return:      `${BASE}/about/return`,
    },
    user: {
        home:        `${BASE}/user`,
        orders:      `${BASE}/user/orders`,
        addresses:   `${BASE}/user/addresses`,
        contacts:    `${BASE}/user/contacts`,
        marketing:   `${BASE}/user/marketing`,
        password:    `${BASE}/user/password`,
        products:    `${BASE}/user/products`,
        order:   id => `${BASE}/user/orders/${id}`,
        invoice: (oid, iid) => `${BASE}/user/orders/${oid}/invoice/${iid}`,
        payment: (oid, pid) => `${BASE}/user/orders/${oid}/payment/${pid}`,
    },
    admin: {
        home:       `/admin/`
    },
    auth: {
        login:      `${BASE}/auth/login`,
        register:   `${BASE}/auth/register`,
        recover:    `${BASE}/auth/recover`,
        reset:      `${BASE}/auth/reset`,
    },
    styles: {
        home:       `${BASE}/styles/`,
        alerts:     `${BASE}/styles/alerts`,
        buttons:    `${BASE}/styles/buttons`,
        colours:    `${BASE}/styles/colours`,
        icons:      `${BASE}/styles/icons`,
        labels:     `${BASE}/styles/labels`,
        logos:      `${BASE}/styles/logos`,
        panels:     `${BASE}/styles/panels`,
        toasts:     `${BASE}/styles/toasts`,
        typography: `${BASE}/styles/typography`,
    }
}

export default URLS