import { ReactComponent as Canon } from '../images/manufacturer/Canon.svg';
import { ReactComponent as Fuji  } from '../images/manufacturer/Fujifilm.svg';
import { ReactComponent as Nikon } from '../images/manufacturer/Nikon.svg';
import { ReactComponent as Micro } from '../images/manufacturer/Micro.svg';
import { ReactComponent as Sony  } from '../images/manufacturer/Sony.svg';

export const BodyTypes = [
  { uri: 'canon',    svg: Canon, logo: 'Canon-white.svg',    title: "Canon" },
  { uri: 'nikon',    svg: Nikon, logo: 'Nikon-white.svg',    title: "Nikon" },
  { uri: 'sony',     svg: Sony,  logo: 'Sony-white.svg',     title: "Sony" },
  { uri: 'micro43',  svg: Micro, logo: 'Micro-white.svg',    title: "Micro 4/3rds" },
  { uri: 'fujifilm', svg: Fuji,  logo: 'Fujifilm-white.svg', title: "Fujifilm" },
];

export let BodyType = { };
BodyTypes.forEach(
  bt => BodyType[bt.uri] = bt
)

export default BodyTypes