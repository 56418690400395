const iconMap = {
  alert:                'warning-ring',
  info:                 'info-ring',
  'info-circle':        'info-ring',
  'check-circle':       'success-ring',
  'exclamation-circle': 'warning-ring',
  'circle-exclamation': 'warning-ring',
  'question-circle':    'question-ring',
};

const Theme = {
  Checkbox: {
    checkedIcon:   'check',
  },
  Spinner: {
    icon: 'aperture',
    color: 'green'
  },
  Saving: {
    color: 'green'
  },
  Loader: {
    overlayColor: 'dark',
    bgColor: 'transparent',
  },
  Search: {
    searchIcon:  'magnify2',
    loadingIcon: 'aperture spin'
  },
  FormSaving: {
    icon: 'aperture',
    color: 'green',
    overlayColor: 'dark',
    bgColor: 'transparent'
  },
  FormErrors: {
    className: 'small',
  },
  Revealable: {
    revealedIcon:   'angle-down',
    unrevealedIcon: 'angle-right',
  },
  Alert: (props, context) => {
    const icon = props.icon ? iconMap[props.icon] || props.icon : undefined;
    return {
      ...props,
      icon,
      stripe: false
    }
  }
};

export default Theme