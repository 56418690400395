import React from 'react'
import Sales from '../../context/Sales'
import { Icon } from '@abw/badger-ui';

export const Input = ({sales}) => {
  const [focus, setFocus] = React.useState(false);
  return <div className={`search-input ${focus ? 'focus' :''}`}>
    <Icon name="magnify2" fixedWidth className="search"/>
    <input
      type="text"
      value={sales.searchText}
      onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}
      // autoFocus
      onChange={e => sales.setSearchText(e.target.value)}
      placeholder='e.g. "24-70mm", "500mm", "TS-E"'
    />
    { sales.searchText?.length
      ? <Icon name="times" fixedWidth className="clear" onClick={ e => sales.setSearchText("") }/>
      : null
    }
  </div>
}

export default Sales.Consumer(Input)