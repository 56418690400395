import React from 'react'
import Sales from '../../../context/Sales'
import CompatibilityNote from '../CompatibilityNote'
import { markupHTML } from '../../../utils'

const Description = ({product}) =>
  <div className="description product-panel scrollbar">
    <h3 className="title">Details</h3>
    <div className="panel-body scroll-y">
      <div className="description" dangerouslySetInnerHTML={{__html:markupHTML(product.description)}}/>
      { product.compatibility?.length
        ? <CompatibilityNote/>
        : null
      }
    </div>
  </div>

export default Sales.Consumer(Description)
