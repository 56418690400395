import { Store, prefixKey } from './Storage'
const MaxRecentItems = 12;

export function Recent(type)  {
  const key   = prefixKey('recent', type);
  const clear = () => {
      Store.removeItem(key);
  };
  const list  = () => {
      const data = Store.getItem(key);
      return data ? JSON.parse(data) : [ ];
  }
  const add   = (item) => {
      // if the item is already in the list we can remove it and add it back to the top of the list,
      // otherwise we just add it to the top of the list
      let stored = list().filter( i => i.id !== item.id );
      stored.unshift(item);
      // remove any items from the end that make the list longer than MaxRecentItems
      if (stored.length > MaxRecentItems) {
          stored.splice(MaxRecentItems, stored.length - MaxRecentItems);
      }
      Store.setItem(key, JSON.stringify(stored));
      return stored;
  };
  return { list, clear, add };
}
