import React from 'react'
import { useLocation } from 'react-router';

export function scrollToId(id, offset) {
    if (typeof document === 'undefined') {
        console.log("Cannot scrollToId(%s) - no document reference", id);
        return;
    }
    let elem = document.getElementById(id);
    if (elem) {
        if (offset && typeof window !== 'undefined') {
            window.scroll({ top: (elem.offsetTop + offset), left: 0, behavior: 'smooth' });
        }
        else {
            elem.scrollIntoView({ behavior: 'smooth' });
        }
    }
    else {
        console.log("Cannot scroll to missing element: #" + id);
    }
}

export function scrollToTop() {
    scrollToId('top');
}

const ScrollToTop = (props) => {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <>{props.children}</>
};

export default ScrollToTop

