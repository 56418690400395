import { GET, POST, ToastAll, ToastErrors, ToastBasket } from './Network'

export const API = {
  sales:                GET('/api2/sales', ToastErrors),
  product:              GET('/api2/product', ToastErrors),
  availability:         GET('/api2/availability', ToastErrors),
  auth: {
    status:             GET('/api2/auth/status', ToastErrors),
    login:              POST('/api2/auth/login', ToastAll),
    register:           POST('/api2/auth/register', ToastAll),
    confirm:            POST('/api2/auth/confirm', ToastAll),
    cancel:             POST('/api2/auth/cancel', ToastAll),
    logout:             POST('/api2/auth/logout', ToastAll),
    recover:            POST('/api2/auth/recover', ToastAll),
    reset:              POST('/api2/auth/reset', ToastAll),
  },
  user: {
    add_contact:        POST('/api2/user/add_contact', ToastAll),
    delete_contact:     POST('/api2/user/delete_contact', ToastAll),
    questions:          POST('/api2/user/questions', ToastAll),
    gdpr_consent:       POST('/api2/user/gdpr_consent', ToastAll),
    change_password:    POST('/api2/user/change_password', ToastAll),
    account:            GET('/api2/user/account', ToastErrors),
    order:              GET('/api2/user/order', ToastErrors),
    add_address:        POST('/api2/user/add_address', ToastAll),
    deactivate_address: GET('/api2/user/deactivate_address', ToastAll),
  },
  autocomplete: {
    town:               GET('/api2/autocomplete/town', ToastErrors),
    county:             GET('/api2/autocomplete/county', ToastErrors),
  },
  basket: {
    load:               GET('/api2/basket/load', ToastErrors),
    add:                POST('/api2/basket/add', ToastBasket),
    remove:             POST('/api2/basket/remove', ToastAll),
    clone:              POST('/api2/basket/clone', ToastAll),
    empty:              POST('/api2/basket/empty', ToastAll),
    hire_starts:        POST('/api2/basket/hire_starts', ToastErrors),
    hire_days:          POST('/api2/basket/hire_days', ToastErrors),
    // delivery
    collect_in_person:  POST('/api2/basket/collect_in_person', ToastErrors),
    delivery_by_courier:POST('/api2/basket/delivery_by_courier', ToastErrors),
    delivery_method:    POST('/api2/basket/delivery_method', ToastErrors),
    delivery_date:      POST('/api2/basket/delivery_date', ToastErrors),
    delivery_type:      POST('/api2/basket/delivery_type', ToastErrors),
    delivery_postcode:  POST('/api2/basket/delivery_postcode', ToastErrors),
    delivery_clear:     POST('/api2/basket/delivery_clear', ToastErrors),
    // return
    return_in_person:   POST('/api2/basket/return_in_person', ToastErrors),
    return_by_customer: POST('/api2/basket/return_by_customer', ToastErrors),
    return_by_courier:  POST('/api2/basket/return_by_courier', ToastErrors),
    return_type:        POST('/api2/basket/return_type', ToastErrors),
    return_date:        POST('/api2/basket/return_date', ToastErrors),
    return_postcode:    POST('/api2/basket/return_postcode', ToastErrors),
    return_clear:       POST('/api2/basket/return_clear', ToastErrors),
    // options
    pay_deposit:        POST('/api2/basket/pay_deposit', ToastErrors),
    claim_discount:     POST('/api2/basket/claim_discount', ToastErrors),
    terms_agreed:       POST('/api2/basket/terms_agreed', ToastErrors),
    gdpr_consent:       POST('/api2/basket/gdpr_consent', ToastErrors),
    availability:       GET('/api2/basket/availability', ToastErrors),
  },
  checkout: {
    set_delivery_address:     POST('/api2/checkout/set_delivery_address', ToastErrors),
    set_delivery_notes:       POST('/api2/checkout/set_delivery_notes', ToastErrors),
    add_delivery_address:     POST('/api2/checkout/add_delivery_address', ToastErrors),
    clear_delivery_address:   POST('/api2/checkout/clear_delivery_address', ToastErrors),
    set_collection_address:   POST('/api2/checkout/set_collection_address', ToastErrors),
    add_collection_address:   POST('/api2/checkout/add_collection_address', ToastErrors),
    set_collection_notes:     POST('/api2/checkout/set_collection_notes', ToastErrors),
    clear_collection_address: POST('/api2/checkout/clear_collection_address', ToastErrors),
    set_billing_address:      POST('/api2/checkout/set_billing_address', ToastErrors),
    add_billing_address:      POST('/api2/checkout/add_billing_address', ToastErrors),
    clear_billing_address:    POST('/api2/checkout/clear_billing_address', ToastErrors),
    payment:                  POST('/api2/checkout/payment', ToastErrors),
  },
  about:{
    faqs:                     GET('/api2/about/faqs', ToastErrors)
  }
};

export default API
