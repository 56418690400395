import React from 'react';
import URLS from '../../config/URLS.jsx';
import { Container, Link } from '@abw/badger-ui';

export const Closed = () =>
  <Container className="tablet mar-t--5">
    <Link to={URLS.xmas} className="xmas" bare={true}>
      <div className="white">
        <h2>We're now closed for Christmas and New Year.</h2>
        <p>
          Of course, you can still place orders for dispatch on or after 6th
          January 2025. Happy Christmas!
        </p>
      </div>
    </Link>
  </Container>

export default Closed