import { ReactComponent as EF } from '../images/mount/canon_ef.svg';
import { ReactComponent as RF } from '../images/mount/canon_rf.svg';
import { ReactComponent as F  } from '../images/mount/nikon_f.svg';
import { ReactComponent as Z  } from '../images/mount/nikon_z.svg';

export const LensMounts = {
  canon: [
    { uri: 'canon_ef', svg: EF, logo: 'canon_ef.svg', name: 'EF', title: 'Canon EF' },
    { uri: 'canon_rf', svg: RF, logo: 'canon_rf.svg', name: 'RF', title: 'Canon RF' },
  ],
  nikon: [
    { uri: 'nikon_f',  svg: F, logo: 'nikon_f.svg',  name: 'F',  title: 'Nikon F'  },
    { uri: 'nikon_z',  svg: Z, logo: 'nikon_z.svg',  name: 'Z',  title: 'Nikon Z'  },
  ]
};

export let LensMount = { };
Object.values(LensMounts).forEach(
  group => group.forEach(
    lm => LensMount[lm.uri] = lm
  )
);

export default LensMounts