import React from 'react'
import { Icon, Link } from '@abw/badger-ui'
import { PublicURL } from '../../config/Site'
import Notices from '../../site/Notices.jsx'

export const HeroBSSR = () =>
  <div className="hero bssr text-center"
    style={{ background: `url(${PublicURL('/img/background/lens1.jpg')}) bottom center no-repeat` }}
  >
    <Notices page="home"/>
    <div className="container">
      <h1 className="ultra">
        <span>book</span>
        <span>shoot</span>
        <span>return</span>
        <span>repeat</span>
      </h1>
      <div className="lhme">
        Lens hire made easy
        <div className="get-started">
          <Link to="search" className="blue button">
            Get started
            <Icon name="angle-right"/>
          </Link>
        </div>
      </div>
      <div className="split-421">
        <div>
          <h2 className="green">book</h2>
          <p>Affordable hire prices.</p>
          <p>Simple and secure online booking.</p>
          <p>No deposit to pay (usually!).</p>
        </div>
        <div>
          <h2 className="green">shoot</h2>
          <p>Hire the lens to get the shots you really want.</p>
          <p>Worldwide insurance included.</p>
        </div>
        <div>
          <h2 className="green">return</h2>
          <p>Choose your preferred return option.</p>
          <p>Hassle-free courier collection available.</p>
        </div>
        <div>
          <h2 className="green">repeat</h2>
          <p>Our customers always come back.</p>
          <p>We pride ourselves on our friendly service.</p>
        </div>
      </div>
    </div>
  </div>

export default HeroBSSR