import React from 'react'
import Trustpilot from '../Trustpilot';
import { Carousel } from 'react-responsive-carousel'
import { NextArrow, PrevArrow } from './LatestKit';

const quotes = [
  [ "Great speedy service, easy to book and arrange delivery and collections! Have used before and always consistent", "Martin Hill" ],
  [ "Simply the best!", "Tina Turner" ],
  [ "Excellent service with a foolproof process", "James Bedster" ],
  [ "All very easy to deal with great value for money.", "Gary Mills" ],
  [ "Easy to order, great choice of lenses, fantastic delivery & collection. The perfect combination!", "Ben McDade" ],
  [ "If in doubt, follow your nose.", "Gandalf the Grey" ],
  [ "I had a few questions whilst book into the website and I made a phone call and received absolutely fantastic service on the phone as well as through the website.", "Owen Mathias" ],
  [ "I've rented from lensesforhire many times and their service, lenses and people are all fantastic!", "Annie" ],
  [ "Life uh.... finds a way.", "Ian Malcolm" ],
  [ "Only sharks with laser beams would be better.", "Matthew Crosby" ],
  [ "Excellent service, very adaptable. Excellent equipment. Everything works like clockworm.", "Martin" ],
  [ "Friendly, helpful, nothing too much trouble.", "Bob Hext" ],
  [ "The Perfect Hire. From initial enquiry to sending the lens back, the process was seamless.", "Barry" ],
  [ "Guess you guys aren't ready for that yet... but your kids are gonna love it.", "Marty Mcfly" ],
];


export const Quotes = ({sales}) => {
  return <div className="quotes pad-v-4">
    <div className="container pictures-carousel pad-v-4">
      <Carousel emulateTouch={true} showThumbs={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={6000}
        renderArrowPrev={(onClickHandler, hasPrev) => <PrevArrow onClick={onClickHandler} hasPrev={hasPrev}/>}
        renderArrowNext={(onClickHandler, hasNext) => <NextArrow onClick={onClickHandler} hasNext={hasNext}/>}
      >
      { quotes.map(
          (quote, n) =><div key={n} className="carousel-item">
            <div className="item">
              <div className="quote-attrib">
                <div className="quote">
                  &ldquo;{quote[0]}&rdquo;
                </div>
                <div className="attrib">
                  {quote[1]}
                </div>
              </div>
            </div>

          </div>
    )}
      </Carousel>
    </div>
    <Trustpilot/>
  </div>
}

export default Quotes