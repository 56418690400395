import React from 'react'
import Sales from '../../context/Sales'
import Availability from './Panel/Availability'
import Features from './Panel/Features'
import Description from './Panel/Description'
import Pictures from './Panel/Pictures'

const Info = ({product}) =>
  <div className="product-info">
    <Description/>
    <Features/>
    <Pictures/>
    { product.active
      ? <Availability/>
      : null
    }
  </div>

export default Sales.Consumer(Info)
