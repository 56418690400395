import React from 'react'
import Sales from '../../context/Sales'
import SelectDays from './SelectDays'
import { priceOrPOA } from '../../utils'

const Price = ({sales, product, selectDays=false}) =>
  <div className="price">
    <div className="money">
      {priceOrPOA(sales.productPrice(product.price_range))}
    </div>
    <div className="duration">
    { selectDays
      ? <SelectDays/>
      : <>for {sales.hireDays()} days </>
    }
    </div>
  </div>

export default Sales.Consumer(Price)