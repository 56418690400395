import React from 'react'
import API from '../utils/API';
import Debug from '../config/Debug';
import Generator from '@abw/react-context-generator'
import { bindHandlers, addDebug, addAPIHandlers } from '../utils/Object'
import { prepareSalesData, prepareProductInfo, prepareBasket, sortMethods } from '../utils/Sales'
import { debounce, Recent, Storage } from '../utils';
import { getConsentAnalytics, getConsentSearch } from '../utils/Cookies';
import analytics from '../site/Analytics'
import { BodyType } from '../config/BodyTypes';
import { ProductType } from '../config/ProductTypes';
import { LensMount } from '../config/LensMounts';

const defaultHireDays = 3;
const storage = Storage('search');
const defaults = storage.load({
  bodyType:       undefined,
  lensMount:      undefined,
  sortOrder:      'name',
  sortDirection:  'ascending',
  sortNew:        true,
});

const recentProducts = Recent('products');
const State = {
    ready:          false,
    products:       undefined,
    prices:         undefined,
    notices:        [ ],
    basket:         { },
    searchText:     "",
    recent:         recentProducts.list(),
    ...defaults
};


class Sales extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...State };
        this.handlers = bindHandlers(
            this,
            'loadSales loadedSales setBodyType setLensMount setCategory selectProduct setSearchText setSortOrder setSortDirection setSortNew parseQueryParams '+
            'productPrice productAvailability ' +
            'loadBasket savedBasket basketAddProduct basketRemoveProduct basketRemoveItem basketCloneItem basketEmpty basketAvailability ' +
            'setHireStarts setHireDays hireDays ' +
            'collectInPerson deliveryByCourier setDeliveryMethod setDeliveryType setDeliveryDate setDeliveryPostcode clearDelivery ' +
            'returnInPerson returnByCustomer returnByCourier setReturnType setReturnDate setReturnPostcode clearReturn ' +
            'setDeliveryAddress setDeliveryNotes clearDeliveryAddress ' +
            'setCollectionAddress setCollectionNotes clearCollectionAddress ' +
            'setBillingAddress clearBillingAddress ' +
            'payDeposit claimDiscount termsAgreed gdprConsent placeOrder ' +
            'loadFAQS clearRecent'
        );
        addAPIHandlers(this);
        addDebug(this, ...Debug.context.sales);
        this.debouncedSearch = debounce(
          this.browse.bind(this),
          300
        );
    }
    componentDidMount() {
        this.loadSales();
    }

    //-----------------------------------------------------------------------------
    // load sales data
    //-----------------------------------------------------------------------------
    loadSales() {
        this.debug("loading sales...");
        this.setState({ loading: true });
        this.request(API.sales)
            .then( data => this.loadedSales(data) );
    }
    loadedSales(data) {
        this.debug("loadedSales: ", data);
        const state = prepareSalesData(data);
        this.setState({
            loading: false,
            ready:   true,
            error:   false,
            ...state
        });
    }

    //-----------------------------------------------------------------------------
    // browse
    //-----------------------------------------------------------------------------
    setBodyType(bodyType) {
        this.debug("setBodyType(%o)", bodyType);
        this.browse({ bodyType, lensMount: undefined, track: true });
    }
    setLensMount(lensMount) {
        this.debug("setLensMount(%o)", lensMount);
        this.browse({ lensMount, track: true });
    }
    setCategory(category) {
        this.debug("setCategory(%o)", category);
        const [productType, lensType] = category ? category.uri.split(':', 2) : [undefined, undefined];
        this.debug("selecting productType:%s  lensType:%s", productType, lensType);
        this.browse({ category, productType, lensType, track: true });
    }
    setSortOrder(sortOrder) {
        this.debug("setSortOrder(%s)", sortOrder);
        this.browse({ sortOrder });
    }
    setSortDirection(sortDirection) {
        this.debug("setSortDirection(%s)", sortDirection);
        this.browse({ sortDirection });
    }
    setSortNew(sortNew) {
        this.debug("setSortNew(%s)", sortNew);
        this.browse({ sortNew });
    }
    setSearchText(searchText) {
        this.debug("setSearchText(%s)", searchText);
        this.setState({ searchText });
        const searchWords = searchText && searchText.length && searchText.toLowerCase().split(' ').filter( w => w.length );
        this.debouncedSearch({ searchText, searchWords });
        // this.browse({ searchText, searchWords });
    }
    parseQueryParams(params) {
      // search params from old site
      //    body_type=canon
      //    name=ts-e
      //    lens_type=Standard
      //    product_type=lens
      //    order=focal_length
      //    reverse=1
      //    layout=grid
      this.debug("parseQueryParams() ", params);
      let query = {
        lensMount: undefined,
        track: true,
      };
      const body      = params.body    || params.body_type;
      const mount     = params.mount;
      const type      = params.type    || params.product_type;
      const search    = params.search  || params.name;
      const sort      = params.sort    || params.order;
      const direction = params.direction || (params.reverse ? 'descending' : undefined);

      if (body) {
        query.bodyType = BodyType[body];
      }
      if (type) {
        // old site allowed multiple items to be specified... we'll just take the first one
        query.productType = type.split('|', 2)[0];
        if (query.productType === 'lighting') {
          query.productType = 'accessory';
        }
        query.category = ProductType[query.productType];
      }
      if (mount) {
        query.lensMount = LensMount[mount];
      }
      if (sort) {
          query.sortOrder = sort;
          query.sortDirection = 'ascending';
      }
      if (direction) {
          query.sortDirection = direction;
      }
      if (Object.keys(query).length > 2) {
        this.debug("browse params: ", query);
        this.browse(query);
      }
      if (search) {
        this.setSearchText(search);
      }
    }

    browse(props) {
        this.debug("browse(%o)", props);
        const {
          bodyType, lensMount, productType, lensType, category,
          searchText, searchWords, sortOrder, sortDirection, sortNew, products
        } = { ...this.state, ...props };
        this.debug(
            "browse [bodyType:%o] [lensMount:%o] [category:%o] [productType:%s] [lensType:%s] [searchText:%s] [searchWords:%o] [sortOrder:%s] [sortDirection:%s] [sortNew:%s]",
            bodyType, lensMount, category, productType, lensType, searchText, searchWords, sortOrder, sortDirection, sortNew
        );
        const browsing = bodyType || lensType || category || searchText;
        let browseResults = undefined;
        let queryParams = { };

      //const search  = params.search  || params.name;
      //const sort    = params.sort    || params.order;
      //const reverse = params.reverse || params.descending;

        if (browsing) {
            browseResults = products;
            if (bodyType) {
                const csuri = bodyType.uri;
                this.debug("Filtering %s products with camera_system_uri === %s", products.length, csuri);
                browseResults = browseResults.filter( p => (p.camera_system_uri || csuri)=== csuri );
                this.debug("Matched %s products", browseResults.length);
                queryParams.body = csuri;
            }
            if (lensMount) {
                const lmuri = lensMount.uri;
                this.debug("Filtering %s products with lens_mount_uri === %s", products.length, lmuri);
                browseResults = browseResults.filter( p => (p.lens_mount_uri || lmuri) === lmuri );
                this.debug("Matched %s products", browseResults.length);
                queryParams.mount = lmuri;
            }
            if (productType) {
                this.debug("Filtering %s products with type === %s", browseResults.length, productType);
                browseResults = browseResults.filter( p => p.type === productType );
                this.debug("Matched %s products", browseResults.length);
                queryParams.type = productType;
            }
            if (lensType) {
                this.debug("Filtering %s products with lens_type === %s", browseResults.length, productType);
                browseResults = browseResults.filter( p => p.lens_type[lensType] );
                this.debug("Matched %s products", browseResults.length);
            }
            if (searchWords) {
                this.debug("Filtering %s products with searchWords === os", browseResults.length, searchWords);
                browseResults = browseResults.filter(
                  product => searchWords.every(
                    word => product.search.indexOf(word) >= 0
                  )
                );
                this.debug("Matched %s products", browseResults.length);
                queryParams.search = searchText;
            }
            if (sortOrder) {
                this.debug("sorting by ", sortOrder);
                const sorter = sortMethods[sortOrder];
                browseResults = browseResults.sort(sorter);
                queryParams.sort = sortOrder;
            }
            if (sortDirection === 'descending') {
                this.debug("reversing order (sort descending) [%s]: ");
                browseResults = browseResults.reverse();
                queryParams.direction = sortDirection;
            }
            if (sortNew) {
                this.debug("pushed new products to start");
                browseResults = [
                  ...browseResults.filter( p => p.new ),
                  ...browseResults.filter( p => ! p.new ),
                ];
            }
            this.debug("browseResults: ", browseResults);
            if (props.track && bodyType && category && getConsentAnalytics()) {
                this.track('view_item_list', lensMount ? lensMount.title : bodyType.title, category.title);
            }
        }
        if (getConsentSearch()) {
            storage.save({
                bodyType,
                lensMount,
                sortOrder,
                sortDirection,
                sortNew
            });
        }
        this.setState({
            bodyType,
            lensMount,
            category,
            productType,
            lensType,
            browsing,
            browseResults,
            searchText,
            searchWords,
            sortOrder,
            sortDirection,
            sortNew,
            queryParams,
            product: undefined
        })
    }

    //-----------------------------------------------------------------------------
    // product selection
    //-----------------------------------------------------------------------------
    selectProduct(uri) {
        this.debug("selectProduct(%s)", uri);
        if (! this.state.product || this.state.product.uri !== uri) {
            let product = uri && (this.state.productIndex[uri] || { uri });
            product.loading = true;
            this.setState(
                { product },
                () => this.loadProduct(uri)
            );
        }
    }
    unselectProduct() {
        this.debug('unselectProduct()');
        this.setState({
            product: undefined,
        });
    }

    //-----------------------------------------------------------------------------
    // load detailed information for a single product
    //-----------------------------------------------------------------------------
    loadProduct(uri) {
        this.debug("loadProduct(%s)", uri);
        this.setState({ loading: "Loading..." });
        API.product({ uri })
            .then( response => this.loadedProduct(response.data) )
            .catch( response => this.error(response) );
    }
    loadedProduct(data, callback) {
        this.debug("loadedProduct(%o)", data);
        const product = prepareProductInfo(data, this.state);
        this.viewedProduct(product);
        this.debug("prepared Product: ", product);
        this.setState(
            {
                loading: false,
                saving:  false,
                error:   false,
                product
            },
            callback
        );
    }
    productAvailability(params) {
        this.debug("productAvailability(%o)", params);
        this.setState({ loading: params.loading || "Loading..." });
        API.availability(params)
            .then( response => this.loadedProduct(response.data) )
            .catch( response => this.error(response) );
    }


    //-----------------------------------------------------------------------------
    // Basket
    //-----------------------------------------------------------------------------
    loadBasket() {
        this.debug("loadBasket()");
        API.basket.load()
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    basketAddProduct(product) {
        const uri = product.uri;
        this.debug("basketAddProduct(%s)", uri, product);
        this.setState({ saving: 'add-product-' + uri });
        this.track('add_to_cart', product.camera_system_name || product.manufacturer_name, product.name);
        API.basket.add({ uri })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    basketRemoveProduct(product) {
        const uri = product.uri;
        this.debug("basketRemoveProduct(%s)", uri, product);
        this.setState({ saving: 'remove-product-' + uri });
        this.track('remove_from_cart', product.camera_system_name || product.manufacturer_name, product.name);
        API.basket.remove({ uri })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    basketRemoveItem(item) {
        this.debug("basketRemoveItem(%s)", item.n, item);
        this.setState({ saving: 'remove-item-' + item.n });
        this.track('remove_from_cart', item.camera_system_name || item.manufacturer_name, item.name);
        API.basket.remove({ item: item.n })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    basketCloneItem(item) {
        this.debug("basketCloneItem(%s)", item.n, item);
        this.setState({ saving: 'clone-item-' + item.n });
        this.track('add_to_cart', item.camera_system_name || item.manufacturer_name, item.name);
        API.basket.clone({ item: item.n })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    basketEmpty() {
        this.debug("basketEmpty()");
        this.setState({ saving: 'empty-basket' });
        API.basket.empty()
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    basketAvailability(params={}) {
        this.debug("basketAvailability(%o)", params);
        this.setState({ loading: params.loading || "Loading..." });
        API.basket.availability(params)
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    savedBasket(data, callback) {
        this.debug("savedBasket(%o)", data);
        const basket = prepareBasket(data.basket);
        this.debug("prepared Basket: ", basket);
        this.setState(
            {
                loading: false,
                saving:  false,
                error:   false,
                basket,
                // other data we might receive from the server
                availability: data.availability,
                st_params: data.st_params,
            },
            callback
        );
    }

    //-----------------------------------------------------------------------------
    // Hire
    //-----------------------------------------------------------------------------
    setHireStarts(hire_starts) {
        this.debug("setHireStarts(%s)", hire_starts);
        this.setState({ saving: 'hire_starts' });
        API.basket.hire_starts({ hire_starts })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    setHireDays(hire_days) {
        this.debug("setHireDays(%s)", hire_days);
        this.setState({ saving: 'hire_days' });
        API.basket.hire_days({ hire_days })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    hireDays() {
        return this.state.basket?.paid_days
          ||   this.state.basket?.hire_days
          ||   defaultHireDays;
    }
    productPrice(priceRange) {
        const { prices } = this.state;
        const range = prices[priceRange] || { };
        return range[this.hireDays()];
    }

    //-----------------------------------------------------------------------------
    // Delivery
    //-----------------------------------------------------------------------------
    collectInPerson() {
        this.debug("collectInPerson()");
        this.setState({ saving: 'delivery_method-person' });
        API.basket.collect_in_person()
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    deliveryByCourier() {
        this.debug("deliveryByCourier()");
        this.setState({ saving: 'delivery_method-courier' });
        API.basket.delivery_by_courier()
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    setDeliveryMethod(delivery_method) {
        this.debug("setDeliveryMethod(%s)", delivery_method);
        this.setState({ saving: 'delivery_method-' + delivery_method });
        API.basket.delivery_method({ delivery_method })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    setDeliveryDate(delivery_date) {
        this.debug("setDeliveryDate(%s)", delivery_date);
        this.setState({ saving: 'delivery_date-' + delivery_date });
        API.basket.delivery_date({ delivery_date })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    setDeliveryType(delivery_type) {
        this.debug("setDeliveryType(%s)", delivery_type);
        this.setState({ saving: 'delivery_type-' + delivery_type });
        API.basket.delivery_type({ delivery_type })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    setDeliveryPostcode(postcode) {
        this.debug("setDeliveryPostcode(%s)", postcode);
        this.setState({ saving: 'delivery-postcode' });
        API.basket.delivery_postcode({ postcode })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    clearDelivery() {
        this.debug("clearDelivery()");
        this.setState({ saving: 'delivery_method-' + this.state.basket?.delivery_method });
        API.basket.delivery_clear()
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }

    //-----------------------------------------------------------------------------
    // Return
    //-----------------------------------------------------------------------------
    returnInPerson() {
        this.debug("returnInPerson()");
        this.setState({ saving: 'return_method-person' });
        API.basket.return_in_person()
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    returnByCustomer() {
        this.debug("returnByCustomer()");
        this.setState({ saving: 'return_method-customer' });
        API.basket.return_by_customer()
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    returnByCourier() {
        this.debug("returnByCourier()");
        this.setState({ saving: 'return_method-courier' });
        API.basket.return_by_courier()
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    setReturnDate(return_date) {
        this.debug("setReturnDate(%s)", return_date);
        this.setState({ saving: 'return_date-' + return_date });
        API.basket.return_date({ return_date })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    setReturnType(return_type) {
        this.debug("setReturnType(%s)", return_type);
        this.setState({ saving: 'return_type-' + return_type });
        API.basket.return_type({ return_type })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    setReturnPostcode(postcode) {
        this.debug("setReturnPostcode(%s)", postcode);
        this.setState({ saving: 'return-postcode' });
        API.basket.return_postcode({ postcode })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    clearReturn() {
        this.debug("clearReturn()");
        this.setState({ saving: 'return_method-' + this.state.basket?.return_method });
        API.basket.return_clear()
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }

    //-----------------------------------------------------------------------------
    // Addresses
    //-----------------------------------------------------------------------------
    setDeliveryAddress(address_id) {
        this.debug("setDeliveryAddress(%s)", address_id);
        this.setState({ saving: 'delivery_address-' + address_id });
        API.checkout.set_delivery_address({ address_id })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    setDeliveryNotes(notes) {
        this.debug("setDeliveryNotes(%s)", notes);
        this.setState({ saving: 'delivery_notes' });
        API.checkout.set_delivery_notes({ notes })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    clearDeliveryAddress() {
        this.debug("clearDeliveryAddress() [%s]", this.state.basket?.delivery_address_id);
        this.setState({ saving: 'delivery_address-' + this.state.basket?.delivery_address_id });
        API.checkout.clear_delivery_address()
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    setCollectionAddress(address_id) {
        this.debug("setCollectionAddress(%s)", address_id);
        this.setState({ saving: 'collection_address-' + address_id });
        API.checkout.set_collection_address({ address_id })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    setCollectionNotes(notes) {
        this.debug("setCollectionNotes(%s)", notes);
        this.setState({ saving: 'collection_notes' });
        API.checkout.set_collection_notes({ notes })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    clearCollectionAddress() {
        this.debug("clearCollectionAddress() [%s]", this.state.basket?.collection_address_id);
        this.setState({ saving: 'collection_address-' + this.state.basket?.collection_address_id });
        API.checkout.clear_collection_address()
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    setBillingAddress(address_id) {
        this.debug("setBillingAddress(%s)", address_id);
        this.setState({ saving: 'billing_address-' + address_id });
        API.checkout.set_billing_address({ address_id })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    clearBillingAddress() {
        this.debug("clearBillingAddress() [%s]", this.state.basket?.billing_address_id);
        this.setState({ saving: 'billing_address-' + this.state.basket?.billing_address_id });
        API.checkout.clear_billing_address()
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }

    //-----------------------------------------------------------------------------
    // Options
    //-----------------------------------------------------------------------------
    payDeposit(pay_deposit) {
        this.debug("payDeposit(%s)", pay_deposit);
        this.setState({ saving: 'pay_deposit' });
        API.basket.pay_deposit({ pay_deposit })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    claimDiscount(code) {
        this.debug("claimDiscount(%s)", code);
        this.setState({ saving: 'claim_discount' });
        API.basket.claim_discount({ code })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    termsAgreed(terms_agreed) {
        this.debug("termsAgreed(%s)", terms_agreed);
        this.setState({ saving: 'terms_agreed' });
        API.basket.terms_agreed({ terms_agreed })
            .then( response => this.savedBasket(response.data) )
            .catch( response => this.error(response) );
    }
    gdprConsent(gdpr_consent, callback) {
        this.debug("gdprConsent(%s)", gdpr_consent);
        this.setState({ saving: 'gdpr_consent' });
        API.basket.gdpr_consent({ gdpr_consent })
            .then( response => this.savedBasket(response.data, callback ? () => callback(response.data) : null) )
            .catch( response => this.error(response) );
    }
    placeOrder(params={}, callback) {
        this.debug("placeOrder(%o)", params);
        this.setState(
          {
            saving: 'place_order',
            error: undefined,
            st_params: undefined,
          },
          () => API.checkout.payment(params)
            .then( response => this.savedBasket(response.data, callback ? () => callback(response.data) : null) )
            .catch( response => this.error(response) )
        );
    }

    //-----------------------------------------------------------------------------
    // FAQs and other about pages
    //-----------------------------------------------------------------------------
    loadFAQS(uri, callback) {
        this.debug("loadFAQS(%s)", uri);
        this.setState({ loading: "FAQS" });
        API.about.faqs()
            .then( response => this.loadedFAQS(response.data, callback) )
            .catch( response => this.error(response) );
    }
    loadedFAQS(data, callback) {
        this.debug("loadedFAQS(%o)", data);
        const faqs = data.faqs;
        this.setState(
            {
                loading: false,
                error:   false,
                faqs
            },
            callback
        );
    }

    //-----------------------------------------------------------------------------
    // Analytics
    //-----------------------------------------------------------------------------
    track(event, category, label) {
        if (getConsentAnalytics()) {
            this.debug('track [%s]  category: %s  label: %s', event, category, label);
            analytics.track(event, { category, label });
        }
    }

    //-----------------------------------------------------------------------------
    // Recently Viewed
    //-----------------------------------------------------------------------------
    viewedProduct(product) {
      recentProducts.add({
        id: product.id,
        uri: product.uri,
        name: product.name,
        new: product.new,
        price_range: product.price_range,
        manufacturer_name: product.manufacturer_name,
        model: product.model,
        picture_uri: product.picture_uri,
        thumbnail_url: product.thumbnail_url
      });
      this.setState({ recent:  recentProducts.list() });
    }
    clearRecent() {
      recentProducts.clear();
      this.setState({ recent:  recentProducts.list() });
    }

    //-----------------------------------------------------------------------------
    // error handling
    //-----------------------------------------------------------------------------
    error(response) {
        this.debug("sales context error: ", response);
        this.setState({
            error: response.message,
            saving: false,
            loading: false,
        });
    }

    //-----------------------------------------------------------------------------
    // render
    //-----------------------------------------------------------------------------
    render() {
        let context = {
            ...this.props,
            ...this.state,
            ...this.handlers
        };
        // call the render prop passing a sales object containing
        // all the properties for this component, all the state,
        // and all of the callable handler functions.
        return this.props.render({
            sales: context,
            basket: context.basket,
            product: context.product,
            notices: context.notices
        });
    }
}

export default Generator(Sales);
