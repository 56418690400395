import React from 'react';
import URLS from '../../config/URLS.jsx';
import { Container, Link } from '@abw/badger-ui';
import { ReactComponent as Xmas } from '../../images/xmas/xmas2022.svg'

export const Xmas2024 = () =>
  <Container className="tablet mar-t--5">
    <Link to={URLS.xmas} className="xmas" bare={true}>
      <Xmas width="400"/>
    </Link>
  </Container>

export default Xmas2024